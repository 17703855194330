import { getDb } from './config';

export async function getOfflinePage(url) {
  const db = await getDb();
  const tx = db.transaction('pages', 'readonly');
  const store = tx.objectStore('pages');
  const ret = await store.get(url);
  return ret;
}

export async function getExpTypes() {
  const db = await getDb();
  const tx = db.transaction('expTypes', 'readonly');
  const store = tx.objectStore('expTypes');
  const ret = await store.getAll();
  return ret;
}


export async function getFeaturedOperators() {
  const db = await getDb();
  const tx = db.transaction('featured', 'readonly');
  const store = tx.objectStore('featured');
  const ret = await store.getAll();
  return ret;
}

export async function getExpType(slug) {
  const db = await getDb();
  const tx = db.transaction('expTypes', 'readonly');
  const store = tx.objectStore('expTypes');
  const index = store.index('slug_idx');
  const ret = await index.get(slug);
  if (ret) {
    ret.operators = await getOperatorByIds(ret.operatorIds)
    ret.experienceTypes = await getExpTypes()
  }
  return ret;
}

export async function getDestination(destinationId) {
  const db = await getDb();
  const tx = db.transaction('destinations', 'readonly');
  const store = tx.objectStore('destinations');
  const ret = await store.get(destinationId);
  if (ret) {
    ret.operators = await getOperatorByIds(ret.operatorIds)
    ret.experienceTypes = await getExpTypes()
  }
  return ret;
}

export async function getOperators() {
  const db = await getDb();
  const tx = db.transaction('operators', 'readonly');
  const store = tx.objectStore('operators');
  const ret = await store.getAll();
  return ret;
}

export async function getOperatorById(id) {
  const db = await getDb();
  const tx = db.transaction('operators', 'readonly');
  const store = tx.objectStore('operators');
  const op = await store.get(id);
  return op;
}

export async function getOperator(slug) {
  const db = await getDb();
  const tx = db.transaction('operators', 'readonly');
  const store = tx.objectStore('operators');
  const index = store.index('slug_idx');
  const op = await index.get(slug);
  return op;
}

export async function getOperatorByIds(ids) {
  const db = await getDb();
  const tx = db.transaction('operators', 'readonly');
  const store = tx.objectStore('operators');
  const operators = [];
  for(let i = 0; i< ids.length; i++) {
    const op = await store.get(ids[i]);
    if (op) operators.push(op)
  }
  return operators;
}

//#region images
// export async function getAllImages() {
//   const db = await getDb();
//   const tx = db.transaction('imageUrls', 'readonly');
//   const store = tx.objectStore('imageUrls');
//   const ret = await store.getAll();
//   return ret;
// }

//#endregion