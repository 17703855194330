/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from '../../utils';
import { SiteLink } from '../../components';
import { getTileStyle } from './expTileStyle';

export function ExpTile({ exp, useSlider }) {
  const tileLink = '/experience/' + exp.slug;
  const imageUrl = utils.site.resourcePath(exp.bannerUrl || '/assets/sample.jpg');
  const title = exp.experienceTypeName;
  const s = getTileStyle();
  return (
    <SiteLink to={tileLink} css={[s.tile, useSlider && s.tileSlider]} >
      <div css={s.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
      <div css={s.tileContent}>
        <div css={s.title}>{title}</div>
      </div>
    </SiteLink>
  )
}
