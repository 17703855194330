/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import debounce from "lodash/debounce";

import { useState, Fragment } from "react";
import { SiteLink } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

import { variables } from "../../cssInJs";
import { IoSearchCircle } from "react-icons/io5";
import { isMin } from "lib/css-emotion";
import utils from "utils";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function toggleMenuAndSearch(e) {
    if (!menuOpened) {
      window.setTimeout(() => {
        window.document.getElementById("searchBar__input").focus();
      }, 200);
    }
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  return (
    <Fragment>
      <header css={[style.top, scrolled && style.topScrolled]} id="top">
        <BackButton />
        <SiteLink to="/" css={[style.logo, scrolled && style.logoScrolled]}>
          <picture>
            <source srcSet="/assets/logos/logo-56.png" media="(min-width: 768px)"></source>
            <source srcSet="/assets/logos/logo-44.png "></source>
            <img css={style.logoImg} src="/assets/logos/logo-56.png" alt="logo" />
          </picture>
        </SiteLink>

        <SearchIcon onClick={toggleMenuAndSearch} />
        <Hamburger opened={menuOpened} toggleMenu={toggleMenu} scrolled={scrolled} />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
      </header>
    </Fragment>
  );
}

function SearchIcon({ onClick }) {
  const [needToRender, setNeedToRender] = useState(false);
  React.useEffect(() => {
    const resized = debounce((e) => {
      setNeedToRender(!needToRender);
    }, 100);
    window.addEventListener("resize", resized);
    return () => window.removeEventListener("resize", resized);
    // eslint-disable-next-line
  }, []);

  const iconSize = isMin.md() ? 60 : 44;
  const iconRight = isMin.md() ? 60 : 44;
  const s = {
    zIndex: 1000,
    position: "fixed",
    right: iconRight,
    color: variables.colorOrange,
    cursor: utils.site.cursor,
  };
  return (
    <div css={s}>
      <IoSearchCircle size={iconSize} onClick={onClick} />
    </div>
  );
}
