import React from 'react';
import env from '../../env';
import { SiteLink } from '../../components';
import { usePost } from '../../components';
import { ErrorPopup, Loading } from '../../components';
import { useRouter } from '../../components';

import { FilterDropdown } from 'site/filterDropdown/filterDropdown';
import { getFilterStyle } from './filterStyle';
import { OperatorList } from '../operatorList/operatorList';
import { isOffline, getOperators } from '@site/_offline';
import utils from 'utils';

const getRegionName = x => x.state ? x.state + ' - ' + x.regionName :  x.regionName
const getRegionKey = (r) => getRegionName(r)

export function SearchResult(props) {
  const { query } = useRouter();
  const post = usePost();
  const keyword = query.q;
  //console.log(keyword);

  React.useEffect(() => {
    const fetchData = async () => {

      //get.send(env.apiBase + '/api/operator/getfeatured');

      if (isOffline()) {
        const filteredOperators = await searchOperatorsFromIndexedDb(keyword);
        post.setResult({ operators: filteredOperators, pages: [] })
      } else {
        post.send(env.apiBase + '/api/search/search', { keyword: keyword });
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  console.log(post)
  const data = post.response;

  const pages = data.pages;
  const operators = data.operators;

  if (pages.length === 0 && operators.length === 0) return (
    <div className="alert alert-danger" role="alert">
      No result found for '{keyword}'.
    </div>
  )
  return (
    <>
      {operators.length > 0 && <div>
        <h3>Listings Found for '{keyword}'</h3>
        <OperatorListWithFilter operators={operators} useSlider={false} />
      </div>}
      {pages.length > 0 && <div>
        <h3 style={{ marginTop: '2rem' }}>Pages Found for '{keyword}'</h3>
        <PageList pages={pages} />
      </div>}
    </>
  );
}

function OperatorListWithFilter({ operators }) {

  const [filters, setFilters] = React.useState({ region: null });
  const s = getFilterStyle();
  
  // const states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']
  // const stateItems = states.map(x => ({ label: `${x}`, value: `${x}` }))

  const regions = getRegionsFromOperators(operators)
  console.log(filters, regions)
  let filteredOperators = filters.region? operators.filter(x => utils.array.contains(x.regions, r => getRegionKey(r) === filters.region.value)) : operators;
  // // sort 
  // const sortFn = (a, b) => -utils.array.caseInsensitiveCompare(a.packageType, b.packageType)
  // filteredOperators = filteredOperators.sort(sortFn)

  return <div>
    {regions.length > 0 && <div className="row justify-content-end" css={s.filters}>
      <div className="col-md-4" css={s.col}>
        <FilterDropdown placeholder="Filter by Region" items={regions} value={filters.region}
          onChange={(val) => { filters.region = val; setFilters({ ...filters }); }} />
      </div>
    </div>}
    <OperatorList operators={filteredOperators} useSlider={false} withFilter={true} />
  </div>
}

function getRegionsFromOperators(operators) {
  const allRegions = []
  operators.forEach(op => allRegions.push(...op.regions))
  
  const regions = allRegions.reduce((acc, r) => { acc[getRegionKey(r)] = (acc[getRegionKey(r)] || 0) + 1; return acc }, {});
  
  //console.log(regions)
  const keys = Object.keys(regions).sort()
  const regionItems = keys.map(x => ({ label: `${x} (${regions[x]})`, value: `${x}` }))
  return regionItems || [];
}

async function searchOperatorsFromIndexedDb(keyword) {
  if (!keyword) return []
  const keywordCleaned = keyword.replace(/ /g, '%')
  const operators = await getOperators();
  const like = utils.text.like
  const filtered = operators.filter(x =>
    like(x.operatorName, '%' + keywordCleaned + '%') ||
    like(x.description, '%' + keywordCleaned + '%')
  )
  console.log(operators.length, filtered.length)
  return filtered
}

function PageList({ pages }) {
  return (
    <div className="pageList row">
      {pages.map((page, index) =>
        <div className="pageListItem col-md-6 col-lg-4" key={index}>
          <div className="pageListItem__inner">
            <SiteLink className="pageListItem__title" to={page.pageUrl}>{page.pageTitle}</SiteLink>
            <div className="pageListItem__highlight" dangerouslySetInnerHTML={{ __html: page.searchHighlight }}></div>
          </div>
        </div>
      )}
    </div>
  );
}