/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import utils from 'utils';
import { SiteLink } from 'components';
import { getSingleBannerStyle } from './singleBannerStyle';

export function SingleBanner(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);
  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const alignV = cmsUtils.payload(item, 'AlignV') || '50%';

  const photoCredit = cmsUtils.payload(item, 'PhotoCredit');
  const photoCreditUrl = cmsUtils.payload(item, 'PhotoCreditUrl');
  
  const cssClass = utils.classNames('cms_item', 'imagePanel', `bannerSize_notused--${heightSet}`, item.cssClass || item.anchorName || '');
  
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));
  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');


  const s = getSingleBannerStyle(heightSet);
  const titlePanel = captionTitle ? (
    linkUrl ? (<SiteLink to={linkUrl} css={s.bannerTitleLink}>
      <div dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
    </SiteLink>) : (<div dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  return (
    <div className={cssClass} css={s.bannerHeight} 
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY:alignV }}>
     
      {titlePanel && <div css={s.bannerTitle}>{titlePanel}</div>}
      
      {(photoCredit && !photoCreditUrl) && <div css={s.photoCredit}>{photoCredit}</div>}
      {(photoCredit && photoCreditUrl) && <SiteLink to={photoCreditUrl} css={s.photoCredit}>{photoCredit}</SiteLink>}
    </div>
  );
}
