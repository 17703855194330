import React from 'react';
import Select from 'react-select'

export function FilterDropdown({ placeholder, isMulti = false, items, value, onChange }) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left'
    }),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  }
  const customTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#eee',
      primary: '#404040',
    },
  })

  return (
    <Select options={items}
      styles={customStyles}
      placeholder={placeholder}
      isMulti={isMulti}
      value={value}
      onChange={onChange}      
      isClearable={(value && Array.isArray(value))? value.some(v => !v.isFixed) : value}
      isSearchable={false}
      theme={customTheme} />
  )
}
