/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import env from 'env';
import utils from 'utils';
import { DownloadApp } from './downloadApp';
import { MetaTag } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
import { PanelCurved } from '../panelCurved/panel';
import { SingleBannerPanel } from 'site/singleBanner/singlePanel';

const r = utils.site.resourcePath

export function DownloadAppLayout() {
  const bannerImage = '/assets/sample.jpg'
  return <React.Fragment>
    <MetaTag data={getMetaTag(bannerImage)} />
    <SingleBannerPanel heightSet={'thin'} bgUrl={r(bannerImage)} />
    <PanelCurved>
      <DownloadApp />
    </PanelCurved>
    <ViewPartial />
  </React.Fragment>
}

function getMetaTag(bannerImage) {

  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;
  const url = '/download';

  return {
    title: 'Download App | ' + titleSuffix,
    description: '',
    keywords: '',
    heroImageUrl: utils.site.fullUrl(bannerImage),
    language: 'English',
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(url),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }

}