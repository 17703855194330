// xs,  sm,  md,  lg,  xl,  xxl     <-- only sample
import { mq } from "../../cssInJs"

// <    576  768  992  1200 1600
export function getPanelStyle() {
  return {
    pannelWrapper: mq({
      marginTop: [0, null, -60, -150],
      minHeight: '400px',
      marginBottom: [0, null, -60, -150],
      zIndex: '10',
    }),
    pannel: mq({
      boxShadow: [null, null, '4px 4px 14px 4px rgba(190,190,190,0.5)'],
      backgroundColor: [null, null, '#f5f5f5'],
      position: 'relative',
      paddingTop: [10, null, 40],
      paddingBottom: [10, null, 0],
      paddingLeft: [0, null, 60],
      paddingRight: [0, null, 60],
      zIndex: '10',
      overflow: 'hidden',
    }),
    pannelCurveBottomWrapper: mq({
      position: 'relative',
      height: [0, 0, 70],
      overflow: 'hidden',
    }),
    pannelCurveBottom: mq({
      boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      borderRadius: '100%',
      position: 'absolute',
      backgroundColor: '#f5f5f5',
      right: '-50%',
      left: '-50%',
      height: 500,
      bottom: '0',
      zIndex: '11',
      marginBottom: 10
    })
  }
}