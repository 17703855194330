/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import utils from 'utils'
import { DestinationMapLoader } from '@site/destinationView/destinationMapLoader';
import { isOffline } from '@site/_offline';

export function DestinationMap(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');
  const destinationId = cmsUtils.payload(item, 'DestinationId');
  //console.log(destinationId)
  
  return <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
  {!isOffline() ? <DestinationMapLoader destinationId={destinationId} /> : <div></div>}
</div>
}
