import React from 'react';
import { isOffline } from '@site/_offline';
import { ErrorPopup as ErrorPopupRaw} from '../lib/ui-error';

// common section or pages
export { NotFound } from './notFound/notFound';
export { AppVersion, AppVersionPage } from '../lib/app-version/app-version-v2';
export { Button } from './form/buttons/button';
export { ErrorMessage } from '../lib/ui-error';
export { Loading } from '../lib/ui-loading-v2';

// common functions
export { usePost, useGet } from '../lib/net-api';
export { MediaQueryStatus } from '../lib/dev-mediaQueryStatus';
export { MetaTag } from '../lib/seo';

export { au } from '../lib/animation';
export { useInstagram } from '../lib/instagram-useInstagram';
export { useRouter, useHistory, useHistoryStore, handleElementLink, SiteLink } from '../lib/router'


// modules
export { InstagramFeed } from '../lib/instagram-feed';

export { YoutubeBanner, YoutubeEmbed, YoutubeEmbedPopup } from '../lib/youtube';

export function ErrorPopup(props) {
  if (isOffline()) {
    console.log(props)
    return null;
  }
  return <ErrorPopupRaw {...props}/>
}