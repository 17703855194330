import React from 'react';
import env from '../../env';
import { useGet } from '../../components';
import { useRouter } from '../../components';
import { Loading, ErrorPopup } from '../../components';
import { NotFound } from '../../components';
import { gtm } from '../../lib/tracking';
import { ExpView } from './expView';
import { isOffline, getExpType } from '@site/_offline';

export function ExpViewLoader() {
  const [ pageLoaded, setPageLoaded ] = React.useState(false);
  const { query } = useRouter();
  const slug = query.id || '';
  const get = useGet();

  React.useEffect(() => {
    const fetchData = async () => {
      setPageLoaded(false);

      const expType = isOffline() ? await getExpType(slug) : null;
      //console.log(expType)
      if (expType) {
        get.setResult(expType)
      } else {
        get.send(env.apiBase + `/api/experience/getExperienceBySlug?slug=${encodeURIComponent(slug)}`);
      }
    }
    fetchData();

    // eslint-disable-next-line
  }, [slug]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return get.containsErrorMessage('no experience') ? <NotFound /> : <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;

  if (get.done() && !pageLoaded) {
    setPageLoaded(true);
    const pageUrl = `/experience/${data.slug}`;
    const pageTitle = data.experienceTypeName;
    gtm.pageView(pageUrl, pageTitle);
  }

  return <ExpView exp={data} />
}
