import { variables, mq } from '../../cssInJs';
import { settings as defaultSettings} from '../tileList/config';

export function getTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  const marginLR = settings.marginLR
  const marginTB = settings.marginTB

  return {
    tile: mq({
      paddingLeft: marginLR.map(x => x === null? null : x / 2),
      paddingRight: marginLR.map(x => x === null? null : x / 2),
      paddingTop: marginTB.map(x => x === null? null : x / 2),
      paddingBottom: marginTB.map(x => x === null? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      color: variables.fontColor,
      textTransform: 'none'
    }),
    tileSlider: mq({
      maxWidth: '100% !important',
      height: '100%'
    }),
    tileBg: mq({
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '56.25%'
    }),
    tileContent: mq({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      lineHeight: 1.3,
      boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      backgroundColor: '#fff',
      margin: [20, 30],
      marginTop: [-40, -50],
      padding: [20]
    }),
    title: mq({
      //fontFamily: variables.familyHeader,
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      lineHeight: 1.3,
      fontWeight: 'bold',
      textAlign: 'center',
    }),
  }
} 

export function getTileListOverridingStyle() {
  return {
    tiles: mq({
      alignItems: 'flex-start',
    })
  }
}