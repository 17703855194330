
export function getDestinationFromRecentFetch(destinationId) {
  const destStr = localStorage.getItem('recentDestination')
  if (destStr) {
    let dest;
    try {
      dest = JSON.parse(destStr)
    } catch (e) { return null }
    if (dest.destinationId === destinationId && (new Date()) - dest.tsFetched < 1000) {
      return dest.data
    }
  }
  return null
}

export function setDestinationToRecentFetch(destinationId, dest) {
  localStorage.setItem('recentDestination', JSON.stringify({
    tsFetched: new Date(),
    destinationId: destinationId,
    data: dest
  }))
}