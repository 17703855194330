import { mq } from 'cssInJs';

export function getFilterStyle() {
  return {
    filters: mq({
      marginTop: [10],
      marginBottom: [10],
      marginLeft: -10,
      marginRight: -10,
    }),
    searchKeyword: mq({
      height: [38],
    }),
    col: {
      paddingLeft: 10,
      paddingRight: 10,
      '@media(min-width: 1200px)': {
        maxWidth: '19%',
        flex: '1 0 19%',
      },
      '@media(min-width: 1600px)': {
        maxWidth: '20%',
        flex: '1 0 20%',
      },
      marginBottom: 3,
    },
    typeOfBusiness: {
      '@media(min-width: 1200px)': {
        maxWidth: '24%',
      },
      '@media(min-width: 1600px)': {
        maxWidth: '20%',
      },
    },
  };
}
