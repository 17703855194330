import { mqBanner, getBannerSize } from 'site/bannerSize/bannerSize';
import { mq, variables} from 'cssInJs'

export function getSingleBannerStyle(heightSet) {
  const bannerSize = getBannerSize(heightSet);

  return {
    bannerHeight: mqBanner({
      height: bannerSize,
      backgroundPositionX: 'center',
      maxWidth: '100vw',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
    }),
    photoCredit: mqBanner({
      color: 'white',
      fontSize: '0.8rem',
      transform: 'rotate(-90deg) translate(-50%, 10px)',
      position: 'absolute',
      top: '50%',
      left: '0',
      textAlign: 'center',
      transformOrigin: 'left top',
      maxWidth: bannerSize.map(x => x * 0.9),
      '&:hover, &:active': {
        color: 'white'
      }
    }),
    bannerTitle: mq({
      textAlign: 'center',
      padding: '.5rem 0 .7rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '80%',
      maxWidth: '1200px',
      zIndex: '10',
      fontWeight: '600',
      color: '#fff',
      fontSize: '1.6rem',
      lineHeight: '1.1',
    }),
    bannerTitleLink: mq({
      fontSize: '1.6rem',
      display: 'inline-block',
      padding: '0.4rem 1rem',
      backgroundColor: variables.primaryColor,
      color: '#fff',
      '&:hover, &:active': {
        color: 'white'
      }
    })
  }
}