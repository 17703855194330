import React from "react";
import InnerHTML from "dangerously-set-html-content";
import { isOffline } from "@site/_offline";

import "./reviewPro.scss";

export function ReviewPro({ embedCode }) {
  if (isOffline()) return null;

  return (
    <div className="reviewProContainer" style={{ marginBottom: "1rem" }}>
      <InnerHTML html={embedCode} />
    </div>
  );
}
