import { mq } from "../../cssInJs";

export function getOperatorStyle() {
  return {
    container: {
      maxWidth: 1200,
    },
    title: mq({
      fontSize: [28, 36, 48, 62],
      marginTop: 0,
    }),
    desc: {
      margin: "1rem 0",
      "p:first-of-type": {
        fontWeight: "bold",
        fontSize: "1.1rem",
      },
    },
  };
}

export function getSummaryStyle() {
  return {
    summary: mq({
      padding: ["10px 0"],
      background: "#eee",
      marginTop: [15, null, 0],
      borderLeft: [null, null, "solid 6px #ddd"],
      paddingLeft: [null, null, "15px"],
      backgroundImage: [null, null, "linear-gradient(to right, #eee, #f5f5f5)"],
    }),
    logoIconWrapper: {},
    logoIcon: {
      maxWidth: 180,
    },

    iconLine: {
      display: "flex",
      margin: "0.3rem 0",
      svg: {
        flex: "0 0 30px",
        margin: "5px",
      },
    },
    iconText: {},
    buttonIcon: {
      svg: {
        transform: "translate(-1px, -2px)",
      },
    },
    websiteLine: {
      margin: "0.3rem 0 0.5rem 0.5rem",
    },
  };
}

export function getCertStyle() {
  return {
    certs: {
      marginTop: "1.2rem",
      marginBottom: "1.2rem",
      display: "flex",
      flexWrap: "wrap",
    },
    cert: {},
    certIcon: {
      maxWidth: 180,
      margin: 5,
    },
  };
}

export function getSocialStyle() {
  return {
    socialIcons: {
      marginTop: "1.5rem",
      display: "flex",
    },
    socialIcon: {
      maxWidth: 40,
      margin: 5,
    },
  };
}

export function getShareButtonStyle() {
  return {
    shareButtons: {
      marginTop: "1.2rem",
      marginBottom: "1.2rem",
    },
    shareButtonTitle: {
      fontSize: "0.8rem",
      marginLeft: "0.5rem",
      marginBottom: "0.3rem",
    },
    shareButton: {
      margin: 5,
    },
  };
}

export function getProductStyle() {
  return {
    products: {
      marginTop: "1.5rem",
    },
    product: mq({
      padding: "0.8rem 0",
      margin: "0 0 0.8rem 0",
      borderBottom: "dashed 1px #ccc",
      display: ["block", "flex"],
    }),
    imgCol: mq({
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: "20%",
      maxWidth: ["100%", 200],
      marginRight: [0, 20],
      marginBottom: [15, 0],
    }),
    img: mq({
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "0",
      paddingBottom: ["56.25%", "100%"],
    }),
    content: {},
    title: mq({
      marginTop: 0,
      //fontSize: [18, 22, 30, 36],
    }),
    desc: {},
  };
}
