/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import utils from '../../utils';
import env from '../../env';
import { MetaTag } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
// import { ProductPagePartial } from '../../cms/pages/partials/productPagePartial';
import { getExpStyle } from './expViewStyle';
import { PanelCurved } from '../panelCurved/panel';

import { OperatorList } from '../operatorList/operatorList';
import { OperatorFilter } from './operatorFilter';
import { SingleBannerPanel } from 'site/singleBanner/singlePanel'
import { getFilterData, setFilterData } from '../map/filterDataHelper';

const r = utils.site.resourcePath

const getRegionName = x => x.state ? x.state + ' - ' + x.regionName :  x.regionName
const getRegionKey = (r) => getRegionName(r)

export function ExpView({ exp }) {
  const s = getExpStyle();
  const heroImageForMeta = exp.bannerUrl ? exp.bannerUrl : '/assets/sample.jpg'
  const bannerImage = exp.bannerUrl ? exp.bannerUrl : '/assets/sample.jpg'

  if (!exp) return null;

  //const shareUrl = env.baseOrigin + '/experience/' + exp.slug;
  return (
    <React.Fragment>
      <MetaTag data={getMetaTag(exp, heroImageForMeta)} />
      {/* <div className={`single-banner banner-item bannerSize--standard`}
        style={{ backgroundImage: utils.css.bgUrlStyle(r(bannerImage)) }}>
      </div> */}
      <SingleBannerPanel heightSet={'standard'} photoCredit={exp.photoCredits} photoCreditUrl={exp.photoCreditsUrl}
        bgUrl={r(bannerImage)} />
      <PanelCurved>
        <div css={s.holder}>
          <h1 css={s.title}>{exp.experienceTypeName}</h1>
          <OperatorListWithFilter operators={exp.operators} experienceTypes={exp.experienceTypes} experienceTypeId={exp.experienceTypeId} />
        </div>
      </PanelCurved>

      {/* <ProductPagePartial /> */}
      <ViewPartial />
    </React.Fragment>
  )
}

function OperatorListWithFilter({ operators, experienceTypes, experienceTypeId }) {
  const [filters, setFilters] = React.useState(getFilterData('expOpListFilter' + experienceTypeId, 1) || { typesOfBusiness: [], activities: [], features: [], keyword: '', region: null });
  const filtered = filterOperators(operators, filters, experienceTypeId);
  const regions = getRegionsFromOperators(operators)

  React.useEffect(() => {
    //console.log('filters changed', filters)
    setFilterData('expOpListFilter' + experienceTypeId, 1, filters)
  }, [filters, experienceTypeId]);

  return <div>
    <OperatorFilter regions={regions} experienceTypes={experienceTypes} experienceTypeId={experienceTypeId} filters={filters} setFilters={setFilters} />
    <OperatorList operators={filtered} useSlider={false} withFilter={true} />
  </div>
}

function getRegionsFromOperators(operators) {
  const allRegions = []
  operators.forEach(op => allRegions.push(...op.regions))
  
  const regions = allRegions.reduce((acc, r) => { acc[getRegionKey(r)] = (acc[getRegionKey(r)] || 0) + 1; return acc }, {});
  
  //console.log(regions)
  const keys = Object.keys(regions).sort()
  const regionItems = keys.map(x => ({ label: `${x} (${regions[x]})`, value: `${x}` }))
  return regionItems || [];
}

function filterOperators(operators, filters, experienceTypeId){
  let expIds = [...(filters.typesOfBusiness || []).map(x => x.value),
    ...(filters.activities || []).map(x => x.value),
    ...(filters.features || []).map(x => x.value),
    experienceTypeId
  ]
  // unique
  expIds = Array.from(new Set(expIds))

  //console.log(operators, filters, experienceTypeId, expIds, filters.region ? filters.region.value : true)
  const filtered = operators.filter(op => 
    expIds.every(expId => op.expTypes.some(x => x.experienceTypeId === expId)) &&
    (filters.region ? utils.array.contains(op.regions, r => getRegionKey(r) === filters.region.value) : true))
  //console.log(filtered)
  return filtered
}

function getMetaTag(exp, heroImage) {
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;
  const url = '/experience/' + exp.slug;

  return {
    title: exp.experienceTypeName + ' | ' + titleSuffix,
    description: '',
    keywords: '',
    heroImageUrl: utils.site.fullUrl(heroImage.imageUrl),
    language: 'English',
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(url),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  }

}