import { mq } from 'cssInJs';
import React from 'react';
import { TileList } from '../tileList/tileList'
import { getTileListStyle } from '../tileList/tileListStyle';
import { OperatorTile } from './operatorTile';

export function OperatorList({ operators, useSlider, numOfTiles = 24, withFilter = false }) {
  const items = operators;
  const [tilesToShow, setTilesToShow] = React.useState(numOfTiles);
  const displayingItems = tilesToShow > 0 && items.length > tilesToShow ? items.slice(0, tilesToShow) : items;
  const showMore = items.length > displayingItems.length;

  const withFilterStyle = getTileListStyle();
  if (withFilter) {
    withFilterStyle.holder = [...withFilterStyle.holder, ...mq({ marginTop: [5, null, 10] })]
  }

  const tiles = displayingItems.map(item => <OperatorTile key={item.operatorId} operator={item} useSlider={useSlider} />)
  return tiles.length > 0 ? <div>
    <TileList tiles={tiles} style={withFilterStyle} useSlider={useSlider} />
    {showMore && (<div className="blogTiles__loadMore">
      <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + 24) }}>LOAD MORE</span></div>)}
  </div> : <div style={{ textAlign: 'center', margin: '2rem', color: 'chocolate', minHeight: '10rem' }}>
      <p>Sorry, no operator found.</p>
    </div>
}
