import { variables, mq } from '../../cssInJs';
import { settings as defaultSettings} from '../tileList/config';
import { rgba } from 'polished';

export function getBlogTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  const marginLR = settings.marginLR
  const marginTB = settings.marginTB

  return {
    tile: mq({
      paddingLeft: marginLR.map(x => x === null ? null : x / 2),
      paddingRight: marginLR.map(x => x === null ? null : x / 2),
      paddingTop: marginTB.map(x => x === null ? null : x / 2),
      paddingBottom: marginTB.map(x => x === null ? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null ? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      color: 'inherit',
      ':hover': {
        textDecoration: 'none',
        color: 'inherit',
        '.tileBg': {
          transform: 'scale(1.02)',
          transition: 'all .2s ease-in-out'
        },
        '.tileContent': {
          backgroundColor: rgba('#00aeef', 0.3),
        }
      }
    }),
    tileSlider: mq({
      maxWidth: '100% !important'
    }),
    tileBg: mq({
      backgroundColor: 'white',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '56.25%',
    }),
    tileContent: mq({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      //boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      backgroundColor: '#fff',
      marginTop: [10],
      padding: [20],
      transition: 'all .2s ease-in-out'
    }),
    title: mq({
      fontFamily: variables.familyHeader,
      fontSize: '1.1rem',
      color: '#00aeef',
      //textTransform: 'uppercase',
      lineHeight: 1.1,
      fontWeight: '900',
      marginBottom: [5,]
    }),
    desc: mq({
      fontSize: '0.7rem',
      display: '-webkit-box',
      WebkitLineClamp: '9',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }),
    info: mq({
      margin: '0.3rem 0',
      fontSize: '0.7rem',
      fontWeight: 'bold'
    }),
    postDate: mq({
      marginRight: '0.5rem'
    })
  }
} 