/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { useRouter } from "../../components";
import { getSearchPanelStyle } from "./searchPanelStyle";
import Typewriter from "typewriter-effect";

export function SearchPanel() {
  const { push } = useRouter();
  const [keyword, setKeyword] = React.useState("");
  const [keywordFocused, setKeywordFocused] = React.useState(false);

  const s = getSearchPanelStyle();
  const searchKeywordRef = React.useRef(null);

  const typewriterInit = (typewriter) => {
    typewriter
      .typeString("Search ECO Tours")
      .pauseFor(2000)
      .deleteAll()
      .typeString("Search ECO Accommodations")
      .pauseFor(2000)
      .deleteAll()
      .typeString("Search ECO Attractions")
      .pauseFor(2000)
      .deleteAll()
      .typeString("Search ECO Destinations")
      .pauseFor(2000)
      .deleteAll()
      .start();
  };

  function search(e) {
    e.preventDefault();
    console.log(keyword);
    push("/search-result?q=" + encodeURIComponent(keyword));
  }

  return (
    <React.Fragment>
      <div css={s.mask}></div>
      <div css={s.search}>
        <div css={s.logo}>
          <img
            src="/assets/logos/Eco_Tourism_Australia_Logo_White.png"
            alt="Eco Tourism Australia"
          />
        </div>
        <div css={s.searchBlurbs}>
          FIND YOUR <br />
          ECO EXPERIENCE
        </div>
        <div css={s.searchBox}>
          <form css={s.form} onSubmit={search}>
            <input
              css={s.searchKeyword}
              type="text"
              className="form-control"
              value={keyword}
              ref={searchKeywordRef}
              onChange={(e) => setKeyword(e.target.value)}
              onFocus={(e) => setKeywordFocused(true)}
              onBlur={(e) => {
                setKeyword("");
                setKeywordFocused(false);
              }}
            />
          </form>
          {!keywordFocused && (
            <div
              css={s.typewriter}
              onClick={(e) => {
                setKeywordFocused(true);
                searchKeywordRef.current.focus();
              }}
            >
              <Typewriter onInit={typewriterInit} options={{ loop: true }} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
