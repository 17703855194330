/** @jsx jsx */
import { jsx } from '@emotion/core';

import { FilterDropdown } from 'site/filterDropdown/filterDropdown';
import { getFilterStyle } from './filterStyle';

export function Filter({ destinations, experienceTypes, filters, setFilters }) {
  // const states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']
  // const stateItems = states.map(x => ({ label: `${x}`, value: `${x}` }))
  const sortFn = (a, b) => a.typeCategorySeq - b.typeCategorySeq;

  const typeCategory_Tour = (experienceTypes || []).filter((x) => x.typeCategory === 'Tour').sort(sortFn);
  const typeCategory_Attraction = (experienceTypes || []).filter((x) => x.typeCategory === 'Attraction').sort(sortFn);
  const typeCategory_Accommodation = (experienceTypes || []).filter((x) => x.typeCategory === 'Accommodation').sort(sortFn);
  const typeCategory_Activity = (experienceTypes || []).filter((x) => x.typeCategory === 'Activity').sort(sortFn);
  const typeCategory_Feature = (experienceTypes || []).filter((x) => x.typeCategory === 'Feature').sort(sortFn);

  //const typeOfBusinessItems = [...typeCategory_Tour, ...typeCategory_Attraction, ...typeCategory_Accommodation].map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` }))
  const activityItems = typeCategory_Activity.map((x) => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` }));
  const featureItems = typeCategory_Feature.map((x) => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` }));
  //const expTypeItems = experienceTypes.map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` }))
  const destinationItems = destinations.map((x) => ({ label: `${x.destinationName}`, value: `${x.destinationId}` }));

  const typeOfBusinessItems = [
    {
      label: 'Tours',
      options: typeCategory_Tour.map((x) => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` })),
    },
    {
      label: 'Accommodations',
      options: typeCategory_Accommodation.map((x) => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` })),
    },
    {
      label: 'Attractions',
      options: typeCategory_Attraction.map((x) => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}` })),
    },
  ];

  const s = getFilterStyle();

  return (
    <div className='row' css={s.filters}>
      <div className='col-md-6' css={(s.col, s.typeOfBusiness)}>
        <FilterDropdown
          placeholder='Type of Business'
          isMulti={true}
          items={typeOfBusinessItems}
          value={filters.typesOfBusiness}
          onChange={(val) => {
            console.log(val, filters);
            filters.typesOfBusiness = val;
            setFilters({ ...filters });
          }}
          clearText='Clear filters'
        />
      </div>
      <div className='col-md-6' css={s.col}>
        <FilterDropdown
          placeholder='Activities'
          isMulti={true}
          items={activityItems}
          value={filters.activities}
          onChange={(val) => {
            console.log(val, filters);
            filters.activities = val;
            setFilters({ ...filters });
          }}
          clearText='Clear filters'
        />
      </div>
      <div className='col-md-6' css={s.col}>
        <FilterDropdown
          placeholder='Features'
          isMulti={true}
          items={featureItems}
          value={filters.features}
          onChange={(val) => {
            console.log(val, filters);
            filters.features = val;
            setFilters({ ...filters });
          }}
          clearText='Clear filters'
        />
      </div>
      <div className='col-md-6' css={s.col}>
        <FilterDropdown
          placeholder='Destinations'
          isMulti={true}
          items={destinationItems}
          value={filters.destinations}
          onChange={(val) => {
            console.log(val, filters);
            filters.destinations = val;
            setFilters({ ...filters });
          }}
          clearText='Clear filters'
        />
      </div>

      <div className='col-md-6' css={s.col}>
        <input
          type='text'
          css={s.searchKeyword}
          className='form-control'
          value={filters.keyword}
          placeholder='Search by Keyword'
          onChange={(e) => {
            filters.keyword = e.target.value;
            setFilters({ ...filters });
          }}
        ></input>
      </div>
      {/* <div className="col-md-4" css={s.col}>
      <FilterDropdown placeholder="Filter by State/Territory" items={stateItems} value={filters.state}
        onChange={(val) => { filters.state = val; setFilters({ ...filters }); }} />
    </div> */}
    </div>
  );
}
