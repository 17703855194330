import React from 'react';
import env from '../../env';
import { useGet } from '../../components';
import { ErrorPopup, Loading } from '../../components';
import { TileList } from '../tileList/tileList'
import { getTileListStyle } from '../tileList/tileListStyle';
import { getTileListOverridingStyle } from './expTileStyle';
import { ExpTile } from './expTile';
import { isOffline, getExpTypes } from '@site/_offline';

export function ExperienceList({ numOfTiles = 6, showLoadMore = true }) {
  const get = useGet();
  React.useEffect(() => {
    const fetchData = async () => {
      //get.send(env.apiBase + '/api/experience/getAll');
      const expTypes = isOffline() ? await getExpTypes() : null;
      if (expTypes) {
        get.setResult(expTypes)
      } else {
        get.send(env.apiBase + '/api/experience/getAll');
      }
    }
    fetchData();

    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  // console.log(get)
  const data = get.response;

  if (data.length === 0) return <div>No records found.</div>
  return <div>
    <ExpList items={data} numOfTiles={numOfTiles} showLoadMore={showLoadMore} />
  </div>
}

export function ExpList({ items, useSlider = false, numOfTiles = 6, showLoadMore = true }) {
  const n = numOfTiles === 0 ? 10000 : numOfTiles
  const [tilesToShow, setTilesToShow] = React.useState(n);
  const displayingItems = items.length > tilesToShow ? items.slice(0, tilesToShow) : items;
  const showMore = showLoadMore && items.length > displayingItems.length;

  const tiles = displayingItems.map(item => <ExpTile key={item.experienceTypeId} exp={item} useSlider={useSlider} />)
  return <div>
    <TileList tiles={tiles} style={getTileListStyle()} overridingStyle={getTileListOverridingStyle()} useSlider={useSlider} />
    {showMore && (<div className="blogTiles__loadMore">
      <span className="thinBtn" onClick={(e) => { setTilesToShow(tilesToShow + n) }}>LOAD MORE</span></div>)}
  </div>
}