import { openDB, deleteDB } from 'idb';
//import { openDB, deleteDB, wrap, unwrap } from 'idb';

export const DB_NAME = 'SiteData_v2';
export const DB_VERSION = 1;
export const IMAGE_CACHE_NAME = 'gtv-img-cache-v1';


export async function getDb() {

  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore('pages', { keyPath: 'pageUrl', autoIncrement: true, });

      const expTypeStore = db.createObjectStore('expTypes', { keyPath: 'experienceTypeId', autoIncrement: true, });
      expTypeStore.createIndex('slug_idx', 'slug', { unique: true });

      const opStore = db.createObjectStore('operators', { keyPath: 'operatorId', autoIncrement: true, });
      opStore.createIndex('slug_idx', 'slug', { unique: true });

      // db.createObjectStore('imageUrls', { keyPath: 'imageUrl', autoIncrement: true, });

      db.createObjectStore('featured', { keyPath: 'operatorId', autoIncrement: true, });

      db.createObjectStore('destinations', { keyPath: 'destinationId', autoIncrement: true, });      
    },
  });

  // remove the trail test
  await deleteDB('SiteData')

  return db;
}