/** @jsx jsx */
import { jsx } from '@emotion/core';

import { FilterDropdown } from 'site/filterDropdown/filterDropdown';
import { getFilterStyle } from './filterStyle';

export function OperatorFilter({ regions, experienceTypes, experienceTypeId, filters, setFilters }) {
  const sortFn = (a, b) => a.typeCategorySeq - b.typeCategorySeq

  const typeCategory_Tour = (experienceTypes || []).filter(x => x.typeCategory === "Tour").sort(sortFn)
  const typeCategory_Attraction = (experienceTypes || []).filter(x => x.typeCategory === "Attraction").sort(sortFn)
  const typeCategory_Accommodation = (experienceTypes || []).filter(x => x.typeCategory === "Accommodation").sort(sortFn)
  const typeCategory_Activity = (experienceTypes || []).filter(x => x.typeCategory === "Activity").sort(sortFn)
  const typeCategory_Feature = (experienceTypes || []).filter(x => x.typeCategory === "Feature").sort(sortFn)

  const activityItems = typeCategory_Activity.map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}`, isFixed: x.experienceTypeId === experienceTypeId }))
  const featureItems = typeCategory_Feature.map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}`, isFixed: x.experienceTypeId === experienceTypeId }))

  const typeOfBusinessItems = [
    {
      label: 'Tours',
      options: typeCategory_Tour.map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}`, isFixed: x.experienceTypeId === experienceTypeId })),
    },
    {
      label: 'Accommodations',
      options: typeCategory_Accommodation.map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}`, isFixed: x.experienceTypeId === experienceTypeId })),
    },
    {
      label: 'Attractions',
      options: typeCategory_Attraction.map(x => ({ label: `${x.experienceTypeName}`, value: `${x.experienceTypeId}`, isFixed: x.experienceTypeId === experienceTypeId })),
    },
  ];
  //console.log(typeOfBusinessItems)

  const typeOfBusinessValue = filters.typesOfBusiness && filters.typesOfBusiness.length > 0? filters.typesOfBusiness : [
    ...typeOfBusinessItems[0].options.filter(x => x.isFixed) || [],
    ...typeOfBusinessItems[1].options.filter(x => x.isFixed) || [],
    ...typeOfBusinessItems[2].options.filter(x => x.isFixed) || [],
    ...filters.typesOfBusiness || []
  ]
  const activityValue = filters.activities && filters.activities.length > 0? filters.activities : [
    ...activityItems.filter(x => x.isFixed) || [],
    ...filters.activities || []
  ]
  const featureValue = filters.features && filters.features.length > 0? filters.features : [
    ...featureItems.filter(x => x.isFixed) || [],
    ...filters.features || []
  ]
  //console.log(typeOfBusinessValue)
  const s = getFilterStyle();

  return <div className="row" css={s.filters}>
    <div className="col-md-6 col-xl-4" css={s.col}>
      <FilterDropdown placeholder="Type of Business" isMulti={true} items={typeOfBusinessItems} value={typeOfBusinessValue}
        onChange={(val) => { console.log(val, filters); filters.typesOfBusiness = val; setFilters({ ...filters }); }} clearText="Clear filters" />
    </div>
    <div className="col-md-6 col-xl-4" css={s.col}>
      <FilterDropdown placeholder="Activities" isMulti={true} items={activityItems} value={activityValue}
        onChange={(val) => { console.log(val, filters); filters.activities = val; setFilters({ ...filters }); }} clearText="Clear filters" />
    </div>
    <div className="col-md-6 col-xl-4" css={s.col}>
      <FilterDropdown placeholder="Features" isMulti={true} items={featureItems} value={featureValue}
        onChange={(val) => { console.log(val, filters); filters.features = val; setFilters({ ...filters }); }} clearText="Clear filters" />
    </div>
    {/* <div className="col-md-6 col-xl-3" css={s.col}>
      <FilterDropdown placeholder="Region" isMulti={false} items={regions} value={filters.region}
        onChange={(val) => { console.log(val, filters); filters.region = val; setFilters({ ...filters }); }} clearText="Clear filters" />
    </div> */}
    {/* <div className="col-md-6 col-xl-3" css={s.col}>
      <input type="text" css={s.searchKeyword} className="form-control" value={filters.keyword} placeholder="Search by Keyword"
        onChange={(e) => { filters.keyword = e.target.value; setFilters({ ...filters }); }}></input>
    </div> */}
  </div>
}