import React from 'react';
import env from '../../env';
import { useGet } from '../../components';
import { Loading, ErrorPopup } from '../../components';
import { isOffline, getDestination } from '@site/_offline';
import { OperatorList } from '@site/operatorList/operatorList';
import { OperatorFilter } from './operatorFilter';
import { getDestinationFromRecentFetch, setDestinationToRecentFetch } from './destinationDataHelper'
import { getFilterData, setFilterData } from '../map/filterDataHelper';

export function OperatorListLoader({destinationId}) {
  const [ loaded, setLoaded ] = React.useState(false);
  const get = useGet();

  React.useEffect(() => {
    const fetchData = async () => {
      const destination = isOffline() ? await getDestination(destinationId) : null;
      if (destination) {
        get.setResult(destination)
      } else {
        const recentlyFetched = getDestinationFromRecentFetch(destinationId)
        if (recentlyFetched) {
          get.setResult(recentlyFetched)
        } else {
          get.send(env.apiBase + `/api/destination/getDestination/${destinationId}`);
          setLoaded(false);
        }
      }
    }
    fetchData();    
    // eslint-disable-next-line
  }, [destinationId]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;

  if (get.done() && !loaded) {
    setLoaded(true);
    setDestinationToRecentFetch(destinationId, data)
  }

  return <OperatorListWithFilter destinationId={destinationId} operators={data.operators} experienceTypes={data.experienceTypes} />
  //return <OperatorList operators={data.operators} useSlider={false} numOfTiles={0} />
}


function OperatorListWithFilter({ destinationId, operators, experienceTypes }) {
  const [filters, setFilters] = React.useState(getFilterData('destOpListFilter' + destinationId, 1) || { typesOfBusiness: [], activities: [], features: [], keyword: '', region: null });
  const filtered = filterOperators(operators, filters);
  
  React.useEffect(() => {
    //console.log('filters changed', filters)
    setFilterData('destOpListFilter' + destinationId, 1, filters)
  }, [filters, destinationId]);

  return <div style={{marginTop: '2rem'}}>
    <OperatorFilter experienceTypes={experienceTypes} filters={filters} setFilters={setFilters} />
    <OperatorList operators={filtered} useSlider={true} numOfTiles={0} withFilter={true} />
  </div>
}

function filterOperators(operators, filters){
  let expIds = [...(filters.typesOfBusiness || []).map(x => x.value),
    ...(filters.activities || []).map(x => x.value),
    ...(filters.features || []).map(x => x.value)
  ]
  // unique
  expIds = Array.from(new Set(expIds))
  //console.log(operators, filters, expIds)
  const filtered = operators.filter(op => expIds.every(expId => op.expTypes.some(x => x.experienceTypeId === expId)))
  //console.log(filtered)
  return filtered
}
