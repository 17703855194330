import { IMAGE_CACHE_NAME } from './config'
import env from 'env';

export async function addCache(filesToCache, log) {
  try {
    const cache = await caches.open(IMAGE_CACHE_NAME)
    await cache.addAll(filesToCache);
  } catch (e) {
    log(filesToCache)
    log(e)
    // try one by one
    const cache = await caches.open(IMAGE_CACHE_NAME)
    for(let i = 0; i < filesToCache.length; i++){
      try {
        await cache.add(filesToCache[i]);
      } catch (e) {
        log(filesToCache[i])
      }
    }
  }
}

export async function checkCache() {
  const cache = await caches.open(IMAGE_CACHE_NAME)
  const result = [];

  for (const req of await cache.keys()) {
    const res = await caches.match(req)
    let size = -2;
    if (res) {
      const blob = await res.clone().blob()
      size = blob ? blob.size : -1;
    }
    //console.log(size, req.url)

    result.push({ url: req.url, size: size })

    //console.log(req)
    // If the request URL matches, add the response to the result
    // if (request.url.endsWith('.png')) {
    //   result.push(await cache.match(request));
    // }
  }
  //console.log(result)
  return result;
}


export async function existInCache(url) {
  const cache = await caches.open(IMAGE_CACHE_NAME)
  const res = await cache.match(url)

  return !!res;
}

export async function getAllImagesInCache() {
  const cache = await caches.open(IMAGE_CACHE_NAME)
  const result = [];
  const basePath = `/sites/${env.siteCode}/media/`

  for (const req of await cache.keys()) {
    result.push(trimUrl(basePath, req.url))
  }
  return result;
}

function trimUrl(basePath, url){
  if (!url) return url;
  const pos = url.indexOf(basePath)
  if (pos > 0){
    //console.log(url.substring(pos, url.length), url, pos)
    return url.substring(pos + basePath.length, url.length)
  } else {
    return url
  }
}


// bodyUsed: false
// cache: "default"
// credentials: "omit"
// destination: ""
// headers: Headers {}
// integrity: ""
// isHistoryNavigation: false
// keepalive: false
// method: "GET"
// mode: "no-cors"
// redirect: "follow"
// referrer: ""
// referrerPolicy: "unsafe-url"
// signal: AbortSignal {aborted: false, onabort: null}
// url: "http://localhost:3100/sites/greenguide/media/listing/lady-musgrave-experience/@thumb/lady-musgrave-experience-cruises-705x470.jpg"