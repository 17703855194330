import utils from 'utils';
import env from 'env';

export function getOfflineMedia(url, setId = 'thumb') {
  const setPath = setId === 'default'? "" : "/@" + setId
  const info = utils.media.getPathDetails(url)

  //console.log(url, info);
  if (info == null) 
    return url;//console.trace()

  return alt(info, setPath);
}

function alt(info, setPath){
  let path = info.baseFolder + setPath + '/' + info.filename
  if (emptyImageCache()) return path;
  //console.log(path, existInCache(path))
  if (!existInCache(path)) {
    path = info.baseFolder + '/' + info.filename
  }
  return path
}

function emptyImageCache() {
  return !window.__imagesInCache
}

function existInCache(url){
  const basePath = `/sites/${env.siteCode}/media/`
  const imagePath = url.replace(basePath, '')
  const urlLower = imagePath.toLowerCase();
  //console.log((window.__imagesInCache || []).length, (window.__imagesInCache || []).filter(x => x.toLowerCase().indexOf(urlLower) >= 0))
  const exists = utils.array.contains(window.__imagesInCache || [], x => x.toLowerCase().indexOf(urlLower) >= 0) 
  //console.log(url, imagePath, urlLower, exists)
  return exists
}