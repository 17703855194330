import React from "react";
import parse from "html-react-parser";
import { isOffline } from "@site/_offline";

let src = "";
export function TripAdvisor({ embedCode }) {
  if (isOffline()) return null;
  const content = parse(embedCode || "", { replace });
  const id = "trip";

  // // Creates a container dynamically
  // let appContainer = document.createElement(`div`);

  // // Gets all <script>s on page and puts'em into an array.
  // let scriptTags = Array.from(document.getElementsByTagName(`script`));

  // // Filters scripts to find the one we need.
  // let targetScript = scriptTags.filter(
  //     scriptTag => scriptTag.src === src
  // );

  // // Uh oh, we got either too many or too few,
  // // it might be bad, better stop right here.
  // if( targetScript.length !== 1 ) return;

  // // Inserts app container before the script.
  // document.body.insertBefore( appContainer, targetScript[0] );

  // // Renders things inside the container
  // return ReactDOM.render (
  //   <div>{content}</div>,
  //     appContainer
  // );

  // load script

  setTimeout(() => {
    const existing = document.querySelector('script[src*="tripadvisor"]');
    console.log(existing);
    if (existing) existing.remove();
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = () => {
      console.log("tripAdvisor loaded");

      setTimeout(() => {
        console.log("tripAdvisor activate");
        for (let ii = 0; ii < window.taValIndex; ii = ii + 1) {
          const fname = window.taValList[ii];
          fname();
        }
      }, 500);
    };
    script.onerror = (error) => console.log(error);
    const el = document.getElementById(id);
    if (el) el.appendChild(script);
  }, 100);

  return (
    <div id={id} className="tripAdvisorContainer">
      {content}
    </div>
  );
}

function replace(node) {
  //console.log(node)
  if (node.type === "script") {
    src = node.attribs.src;
    console.log(src);

    //<script src="https://www.tripadvisor.com.au/WidgetEmbed-selfserveprop?border=true&amp;popIdx=true&amp;iswide=false&amp;locationId=3519666&amp;display_version=2&amp;uniq=315&amp;rating=true&amp;lang=en_AU&amp;nreviews=5&amp;writereviewlink=true" async=""></script>

    // loadScript(src, null, true).then(data => {
    //   console.log('tripAdvisor loaded', data);
    // })
  }
}

//https://stackoverflow.com/questions/57325438/how-can-i-render-a-react-js-component-directly-without-a-target-container
