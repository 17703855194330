import { mqBanner, getBannerSize } from '../../../site/bannerSize/bannerSize';

export function getImageBannerStyle(heightSet) {
  const bannerSize = getBannerSize(heightSet);

  return {
    bannerHeight: mqBanner({
      height: bannerSize
    }),
    photoCredit: mqBanner({
      color: 'white',
      fontSize: '0.8rem',
      transform: 'rotate(-90deg) translate(-50%, 10px)',
      position: 'absolute',
      top: '50%',
      left: '0',
      textAlign: 'center',
      transformOrigin: 'left top',
      maxWidth: bannerSize.map(x => x * 0.9)
    })
  }
}