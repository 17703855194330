/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { Button } from 'site/button/button';
import { getDownloadAppRibbonStyle } from './downloadAppRibbonStyle';

import Hammer from '@egjs/hammerjs'
import { gsap } from 'gsap';

export function DownloadAppRibbon() {

  const sliderRef = React.useRef(null);
  const sliderStatusRef = React.useRef('expanded');
  const sliderDataRef = React.useRef({});

  React.useEffect(() => {
    sliderDataRef.current = { currentTranslateX: 0, size: getSize(sliderRef.current), tsEventHandled: new Date() }
    close(sliderRef.current, sliderStatusRef, sliderDataRef)

    const hammer = new Hammer.Manager(sliderRef.current);
    hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 }));
  
    hammer.on('panstart pan panend', e => {
      // console.log(e.type, e)
      if (e.type === 'panend') {
        toggle(sliderRef.current, sliderStatusRef, sliderDataRef, e.deltaX)
      } else if (e.type === 'pan') {
        moveDeltaTmp(sliderRef.current, sliderStatusRef, sliderDataRef, e.deltaX)
      }
    });

    return () => {
      if (hammer) hammer.destroy();
    }
    // eslint-disable-next-line 
  }, []);

  function toggleSlider(e) {
    e.preventDefault();
    e.stopPropagation();
    const dt = (new Date()) - sliderDataRef.current.tsEventHandled;
    if (dt < 100) return;
    toggle(sliderRef.current, sliderStatusRef, sliderDataRef, 0)
  }

  const s = getDownloadAppRibbonStyle();
  return <div css={s.ribbon} ref={sliderRef}>
    <div css={s.handle} onClick={toggleSlider}>DOWNLOAD APP</div>
    <div css={s.content}>
    <h4>THE GREEN TRAVEL GUIDE APP</h4>
    <p>This website is a Progressive Web App(PWA) that can be fully downloaded onto your device to allow for offline use.</p>
    <Button buttonStyle="small" to="/download">DOWNLOAD</Button>
    </div>
  </div>
}

//#region slider functions

function move(el, dx) {
  el.style.transform = `translate3d(${dx}px, -50%, 0)`;
}

function moveDeltaTmp(el, sliderStatusRef, sliderDataRef, delta){
  const sliderStatus = sliderStatusRef.current
  const sliderData = sliderDataRef.current
  const x = getBase(sliderStatus, sliderData) + delta;

  sliderData.currentTranslateX = x < 0? 0 : (x > sliderData.size.max ? sliderData.size.max : x);
  move(el, sliderData.currentTranslateX);
}

function toggle(el, sliderStatusRef, sliderDataRef, delta){
  const sliderStatus = sliderStatusRef.current
  const sliderData = sliderDataRef.current
  sliderData.tsEventHandled = new Date();
  if ((delta < 0 && sliderStatus === 'expanded') || (delta > 0 && sliderStatus === 'collapsed')) return;
  const targetX = getTarget(sliderStatus, sliderData);  
  gsap.fromTo(el, {x: sliderData.currentTranslateX}, {duration: 0.3, x: targetX, ease: 'power1'});
  sliderData.currentTranslateX = targetX;
  sliderStatusRef.current = sliderStatus === 'collapsed'? 'expanded' : 'collapsed'
}

function close(el, sliderStatusRef, sliderDataRef) {
  const sliderData = sliderDataRef.current
  sliderStatusRef.current = 'collapsed';
  sliderData.currentTranslateX = sliderData.size.max;
  move(el, sliderData.currentTranslateX);
}

// function open(el, sliderStatusRef, sliderData) {
//   sliderStatusRef.current = 'expanded';
//   sliderData.currentTranslateX = sliderData.size.min;
//   move(el, sliderData.currentTranslateX);
// }

function getSize(el) {
  return { min: 0, max: el.offsetWidth };
}

function getBase(sliderStatus, sliderData) {
  return sliderStatus === 'collapsed'? sliderData.size.max : sliderData.size.min;
}

function getTarget(sliderStatus, sliderData) {
  return sliderStatus === 'collapsed'? sliderData.size.min : sliderData.size.max;
}

//#endregion