const overloading = {
  breakpointNames: ["sm", "md", "lg", "xl", "xxl"],
  breakpoints: [576, 768, 992, 1200, 1600],
};

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"roboto-condensed",serif',

  primaryColor: "#6d8020",
  fontColor: "#454949",
  bgColor: "#f5f5f5",
  colorOrange: `#d8d366`,
  colorBlue: "#a4b4d1",

  fontWeightLight: 300,
  fontWeightNormal: 300,
  fontWeightBold: 900,

  ...overloading,
};

export default variables;
