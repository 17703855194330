import React from 'react';
import cmsUtils from '../cmsUtils';
import { ExperienceList } from '../../experienceList/experienceList';

export const renderRule = {
  match: (item, cmsOption) => (cmsUtils.getCodeJson(item) || {})['control'] === 'experience-list', 
  itemElt: (item, cmsOption) => {
    const json = cmsUtils.getCodeJson(item) || {}
    const numOfTiles = json.numOfTiles >= 0? json.numOfTiles : 6
    const showLoadMore = json.showLoadMore !== false && json.showLoadMore !== 'false'

    // console.log(json, numOfTiles, showLoadMore)
    return <ExperienceList numOfTiles={numOfTiles} showLoadMore={showLoadMore}/>
  }
}