import React from 'react';
import env from '../../env';
import { useGet } from '../../components';
import { ErrorPopup, Loading } from '../../components';
import { OperatorList } from './operatorList';
import { isOffline, getFeaturedOperators } from '@site/_offline';

export function FeaturedList() {

  const get = useGet();
  React.useEffect(() => {
    const fetchData = async () => {

      //get.send(env.apiBase + '/api/operator/getfeatured');

      const operators = isOffline() ? await getFeaturedOperators() : null;
      if (operators) {
        get.setResult(operators)
      } else {
        get.send(env.apiBase + '/api/operator/getfeatured');
      }
    }
    fetchData();


    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  // console.log(get)
  const data = get.response;

  if (data.length === 0) return <div>No records found.</div>
  return <OperatorList operators={data} useSlider={true} />
}
