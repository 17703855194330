/** @jsx jsx */
import { jsx } from '@emotion/core'
import { SiteLink } from '../../components';
import { getButtonStyle } from '../button/buttonStyle';

export function Button({ children, buttonStyle, to, ...other }) {
  const s = getButtonStyle(buttonStyle);
  if (to) {
    return <SiteLink css={s.button} to={to} {...other}><span css={s.buttonBg} className="buttonBg"></span><span css={s.buttonTxt}>{children}</span></SiteLink>
  }

  return null;
}


