/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import env from '../../../../env';
//import cmsUtils from '../../cmsUtils';
import utils from 'utils';
import { Loading, ErrorPopup, useGet } from '../../../../components';
import { GoogleMapMemo } from "../../../map/googleMap2";

export function AllDestinationMap(props){
    
    const [ loaded, setLoaded ] = React.useState(false);
    const renderMarkersRef = React.useRef(null);
    const get = useGet();

    React.useEffect(() => {
        const fetchData = async () => {
            get.send(env.apiBase + `/api/destination/allgetdestination`);
            setLoaded(false);
        };
        fetchData();    
        // eslint-disable-next-line
    }, []);

    if (get.loading()) {
        return <Loading />
    } else if (get.hasErrors()) {
        return <ErrorPopup title="Loading Error" errors={get.errors} />
    }
    
    const data = get.response;
    console.log("ABCCCC222222 : ", data);

    if (get.done() && !loaded) {
        setLoaded(true);
    }

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');
    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                (data && data.length > 0) &&
                <GoogleMapMemo 
                    renderMarkersRef={renderMarkersRef}
                    initialMarkers={data}
                />
            }
            {
                (data && data.length <= 0) &&
                <h1 style = {{textAlign:'center'}}>No result</h1>
            }
        </div>
    )
}