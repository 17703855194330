//import { isIOS } from './device'

export function isInPWA() {
  if (window.navigator.standalone) {
    return true;
  } else if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }
  return false;
}


export function initInstallEventHandler() {
  //console.log('offline init')
  window.addEventListener('beforeinstallprompt', (e) => {
    console.log('beforeinstallprompt', e)
    // Prevent the mini-infobar from appearing on mobile <-- we are not doing this.
    //e.preventDefault();
    // Stash the event so it can be triggered later.
    window.deferredPrompt = e;
    window.deferredPromptResolved = false;
    // Update UI notify the user they can install the PWA
    //showInstallPromotion();
    window.dispatchEvent(new Event('__pwaInstallPrompt'))
  });
  window.addEventListener('appinstalled', (evt) => {
    window.dispatchEvent(new Event('__pwaAppInstalled'))
    window.deferredPromptResolved = true;
  });

}

// export function getInstalledPWA() {
//   const relatedApps = await navigator.getInstalledRelatedApps();
//   relatedApps.forEach((app) => {
//     console.log(app.id, app.platform, app.url);
//   });
// }
