
export function getFilterData(filterType, ver) {
  const dataStr = localStorage.getItem('opFilter_' + filterType)
  if (dataStr) {
    let data
    try {
      data = JSON.parse(dataStr)
    } catch (e) { return null }
    if (data && data.ver === ver) {
      return data.filters
    }
  }
  return null
}

export function setFilterData(filterType, ver, filters) {
  localStorage.setItem('opFilter_' + filterType, JSON.stringify({
    ver: ver,
    filters: filters
  }))
}