import { variables, mq } from "../../cssInJs";
import { rgba } from "polished";

export function getSearchPanelStyle(heightSet) {
  return {
    mask: {
      content: '""',
      // background: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==') repeat`,
      backgroundColor: rgba("#000", 0.4),
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      zIndex: "2",
    },
    search: mq({
      position: "absolute",
      top: ["50%", "50%", "45%", "40%"],
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      zIndex: 10,
      width: ["85%", "auto"],
    }),
    logo: mq({
      margin: "auto",
      maxWidth: [140, 180, null, 220, null, 260],
      img: {
        maxWidth: "100%",
      },
    }),
    searchBlurbs: mq({
      marginTop: 15,
      //         xs  sm  md    lg  xl    xxl
      fontSize: [36, 40, 50, 60, null, 80],
      fontFamily: variables.familyHeader,
      color: "white",
      lineHeight: 0.9,
    }),
    searchBox: mq({
      position: "relative",
    }),
    searchKeyword: mq({
      border: "none",
      fontSize: [20, 24, null, null, null, 30],
      background: rgba("#fff", 0.85),
      marginTop: "20px",
      borderRadius: "15px",
      fontWeight: "bold",
    }),
    typewriter: mq({
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      color: variables.fontColor,
      fontSize: [20, 24, null, null, null, 30],
      fontWeight: "bold",
      padding: "0.375rem 0.75rem",
      lineHeight: "1.5",
      textAlign: "left",
    }),
  };
}
