import React from 'react';
import env from '../../env';
import { useGet } from '../../components';
import { useRouter } from '../../components';
import { Loading, ErrorPopup } from '../../components';
import { NotFound } from '../../components';
import { gtm } from '../../lib/tracking';
import { OperatorView } from './operatorView';
import { isOffline, getOperator } from '@site/_offline';

export function OperatorViewLoader() {
  const [ pageLoaded, setPageLoaded ] = React.useState(false);
  const { query, Redirect } = useRouter();
  const slug = query.id || '';
  const get = useGet();

  React.useEffect(() => {
    const fetchData = async () => {
      const operator = isOffline() ? await getOperator(slug) : null;
      if (operator) {
        get.setResult(operator)
      } else {
        get.send(env.apiBase + `/api/operator/getOperatorBySlug?slug=${encodeURIComponent(slug)}`);
        setPageLoaded(false);
      }
    }
    fetchData();    
    // eslint-disable-next-line
  }, [slug]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return get.containsErrorMessage('no operator') ? <NotFound /> : <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  const data = get.response;

  const redirectTo = get.response.redirectTo;
  if (redirectTo) {
    if (redirectTo.indexOf('http') >= 0 && typeof window !== `undefined`) {
      return window.location.href = redirectTo;
    } else {
      return <Redirect to={redirectTo} />
    }
  }

  if (get.done() && !pageLoaded) {
    setPageLoaded(true);
    const pageUrl = `/listing/${data.slug}`;
    const pageTitle = data.operatorName;
    gtm.pageView(pageUrl, pageTitle);
  }

  return <OperatorView operator={data} />
}
