/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from '../../utils';
import { SiteLink } from '../../components';
import { getTileStyle } from './operatorTileStyle';
import { Button } from '../button/button';
import { ExperienceIcon } from '../operatorView/experienceIcon';

export function OperatorTile({ operator, useSlider }) {
  const tileLink = '/listing/' + operator.slug;
  const imageUrl = utils.site.resourcePath(operator.thumbnailImageUrl);
  const title = operator.operatorName;
  const shortDesc = operator.shortDesc;
  const phoneEl = operator.contactPhone ? <a href={`tel:${utils.text.toTelephoneNumber(operator.contactPhone)}`}>{operator.contactPhone}</a> : null
  const webEl = operator.websiteUrl ? <a href={utils.url.getWebUrl(operator.websiteUrl)} target="_blank" rel="noopener noreferrer">Visit Website</a> : null

  const isPremium = operator.packageType === 'Gold'

  const s = getTileStyle();
  return (
    <div css={[s.tile, useSlider && s.tileSlider]} >
      <SiteLink to={tileLink} css={s.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></SiteLink>
      {isPremium && <div css={s.ribbon}><img src="/assets/featured-ribbon.png" alt="Premium"/></div>}
      <div css={s.tileContent}>
        <SiteLink to={tileLink} css={s.title}>{title}</SiteLink>
        <ExperienceIcon expTypes={operator.expTypes} />
        <div css={s.desc} dangerouslySetInnerHTML={{__html: shortDesc }}></div>
        <div css={s.readMore}>
          <Button to={tileLink} buttonStyle="small" target="_blank">VIEW MORE &gt;</Button>
        </div>
        <div css={s.contactLine}>
          {phoneEl && <span css={s.phone}>Ph: {phoneEl}</span>}
          {phoneEl && webEl && <span css={s.sperator}>|</span>}
          {webEl && <span css={s.web}>{webEl}</span>}
        </div>
      </div>
    </div>
  )
}
