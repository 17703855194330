import { variables, mq } from '../../cssInJs';
import { settings as defaultSettings} from '../tileList/config';
import { darken } from 'polished';

export function getTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  const marginLR = settings.marginLR
  const marginTB = settings.marginTB

  return {
    tile: mq({
      paddingLeft: marginLR.map(x => x === null? null : x / 2),
      paddingRight: marginLR.map(x => x === null? null : x / 2),
      paddingTop: marginTB.map(x => x === null? null : x / 2),
      paddingBottom: marginTB.map(x => x === null? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column'
    }),
    tileSlider: mq({
      maxWidth: '100% !important',
      height: '100%'
    }),
    tileBg: mq({
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '56.25%'
    }),
    ribbon: mq({
      position: 'absolute',
      width: '35%',
    }),
    tileContent: mq({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      lineHeight: 1.3,
      boxShadow: '4px 4px 14px 4px rgba(190,190,190,0.5)',
      backgroundColor: '#fff',
      margin: [20, 30],
      marginTop: [-40, -50],
      padding: [20]
    }),
    title: mq({
      //fontFamily: variables.familyHeader,
      fontSize: '1.1rem',
      textTransform: 'uppercase',
      lineHeight: 1.3,
      fontWeight: 'bold',
      marginBottom: [5,],
      color: variables.fontColor
    }),
    desc: mq({
      fontSize: '0.9rem',
      display: '-webkit-box',
      WebkitLineClamp: '9',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      margin: '0.5rem 0'
    }),
    readMore: mq({
      marginTop: 'auto',
      paddingTop: 15,
    }),
    contactLine: {
      marginTop: '0.5rem',
      '&, a': {
        letterSpacing: 1,
        fontSize: '0.8rem',
        color: variables.colorOrange,
        'a:hover': {
          color: darken(0.1, variables.colorOrange),
        },
      }
    },
    sperator: {
      margin: '0 0.6rem'
    }
  }
} 