/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import utils from "../../utils";
import env from "../../env";
import { MetaTag, SiteLink } from "../../components";
import { ViewPartial } from "../../cms/pages/partials/viewPartial";
// import { ProductPagePartial } from '../../cms/pages/partials/productPagePartial';
import parse from "html-react-parser";
import debounce from "lodash/debounce";

import { Gallery } from "../gallery/gallery";
import { ShareButtons } from "../shareButtons/shareButtons";
import { TripAdvisor } from "../tripAdvisor-v1/tripAdvisor";
import { ReviewPro } from "../reviewPro/reviewPro";

import { getOperatorStyle, getSummaryStyle, getCertStyle, getSocialStyle, getProductStyle } from "./operatorViewStyle";
import { PanelCurved } from "../panelCurved/panel";
import { ExperienceIcon } from "./experienceIcon";

import { FaRegBuilding, FaPhone, FaGlobe, FaCompass, FaRegCalendarPlus } from "react-icons/fa";
import { GoLinkExternal } from "react-icons/go";

const r = utils.site.resourcePath;
const set = utils.media.getImageSetPath;
const isPremium = (operator) => operator.packageType === "Gold";

function getHeroImage(operator) {
  if (operator.heroImageUrl) return { imageUrl: operator.heroImageUrl, alt: operator.operatorName };
  return operator.images.length > 0 ? operator.images[0] : null;
}

export function OperatorView({ operator }) {
  const s = getOperatorStyle();
  const heroImageUrl = getHeroImage(operator);
  const heroImageForMeta = heroImageUrl || { imageUrl: "/assets/product-empty.png", alt: operator.operatorName };
  const heroImage = isPremium(operator) && heroImageUrl ? heroImageUrl.imageUrl : "/assets/sample.jpg";
  const images = operator.images || [];

  const [isMobile, setIsMobile] = React.useState(utils.site.isMobile());

  React.useEffect(() => {
    const resized = debounce((e) => {
      setIsMobile(utils.site.isMobile());
    }, 100);

    window.addEventListener("resize", resized);
    return () => {
      window.removeEventListener("resize", resized);
    };
  }, []);

  if (!operator) return null;

  const shareUrl = env.baseOrigin + "/listing/" + operator.slug;
  const pageTitle = operator.operatorName;

  return (
    <React.Fragment>
      <MetaTag data={getMetaTag(operator, heroImageForMeta)} />
      <div
        className={`single-banner banner-item bannerSize--${isPremium(operator) ? "standard" : "thin"}`}
        style={{ backgroundImage: utils.css.bgUrlStyle(r(heroImage)) }}
      ></div>

      <PanelCurved>
        <div css={s.holder}>
          <h1 css={s.title}>{operator.operatorName}</h1>
          <ExperienceIcon expTypes={operator.expTypes} />
          <div className="row">
            <div className="col-md-8 order-2 order-md-1">
              <div css={s.desc} dangerouslySetInnerHTML={{ __html: operator.description }}></div>
              <Products operator={operator} />
              <Gallery images={images} />
            </div>
            <div className="col-md-4 order-1 order-md-2">
              <Summary operator={operator} />
              <Certs operator={operator} />
              {!isMobile && (
                <React.Fragment>
                  {operator.reviewProCode && <ReviewPro embedCode={operator.reviewProCode} />}
                  {operator.tripAdvisorCode && <TripAdvisor embedCode={operator.tripAdvisorCode} />}
                  <ShareButtons shareUrl={shareUrl} pageTitle={pageTitle} />
                </React.Fragment>
              )}
            </div>
          </div>

          {isMobile && (
            <React.Fragment>
              {operator.reviewProCode && <ReviewPro embedCode={operator.reviewProCode} />}
              {operator.tripAdvisorCode && <TripAdvisor embedCode={operator.tripAdvisorCode} />}
              <ShareButtons shareUrl={shareUrl} pageTitle={pageTitle} />
            </React.Fragment>
          )}
        </div>
      </PanelCurved>

      {/* <ProductPagePartial /> */}
      <ViewPartial />
    </React.Fragment>
  );
}

function Summary({ operator }) {
  console.log(operator);
  const getRegionName = (x) => (x.state ? x.state + " - " + x.regionName : x.regionName);

  const s = getSummaryStyle();
  const addrLines = [];
  if (operator.address) addrLines.push(operator.address);
  if (operator.suburb && operator.postcode) addrLines.push(`${operator.suburb}, ${operator.postcode}`);
  else if (operator.suburb) addrLines.push(`${operator.suburb}`);
  else if (operator.postcode) addrLines.push(`${operator.postcode}`);
  if (operator.state) addrLines.push(operator.state);

  const phoneEl = operator.contactPhone ? <a href={`tel:${utils.text.toTelephoneNumber(operator.contactPhone)}`}>{operator.contactPhone}</a> : null;
  const webEl = operator.websiteUrl ? (
    <a href={utils.url.getWebUrl(operator.websiteUrl)} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
      <FaGlobe /> Visit Website
    </a>
  ) : null;
  const bookingEl = operator.bookingUrl ? (
    <a href={utils.url.getWebUrl(operator.bookingUrl)} className="btn btn-danger btn-emphasis" target="_blank" rel="noopener noreferrer">
      <FaRegCalendarPlus /> BOOK NOW
    </a>
  ) : null;

  return (
    <div css={s.summary}>
      {operator.logoUrl && (
        <div css={s.logoIconWrapper}>
          <img css={s.logoIcon} src={r(operator.logoUrl)} alt={operator.operatorName} />
        </div>
      )}
      {/* {operator.region && <div css={s.iconLine}><FaCompass /> <div css={s.iconText}>{operator.region}</div></div>} */}
      {operator.regions &&
        operator.regions.map((region) => (
          <div key={region.regionId} css={s.iconLine}>
            <FaCompass /> <div css={s.iconText}>{getRegionName(region)}</div>
          </div>
        ))}
      {operator.contactPhone && (
        <div css={s.iconLine}>
          <FaPhone /> <div css={s.iconText}>{phoneEl}</div>
        </div>
      )}
      {addrLines.length > 0 && (
        <div css={s.iconLine}>
          <FaRegBuilding />{" "}
          <div css={s.iconText}>
            {addrLines.map((x, index) => (
              <div key={index}>{x}</div>
            ))}
          </div>
        </div>
      )}
      {/* {operator.websiteUrl && <div css={s.iconLine}><FaGlobe /> <div css={s.iconText}>{webEl}</div></div>} */}
      {operator.websiteUrl && (
        <div css={s.websiteLine}>
          {" "}
          <div css={css(s.iconText, s.buttonIcon)}>{webEl}</div>
        </div>
      )}
      {operator.bookingUrl && (
        <div css={s.websiteLine}>
          {" "}
          <div css={css(s.iconText, s.buttonIcon)}>{bookingEl}</div>
        </div>
      )}

      <p style={{ margin: "0.5rem", fontSize: "0.8em" }}>The Green Travel Guide is commission-free; you will book directly with the operator</p>

      <Socials operator={operator} />
    </div>
  );
}

function Certs({ operator }) {
  if (!operator.certifications) return null;
  const s = getCertStyle();
  return (
    <div css={s.certs}>
      {operator.certifications.map((cert) => (
        <div key={cert.certificationId}>
          {cert.linkUrl ? (
            <a href={cert.linkUrl} target="_blank" rel="noopener noreferrer" css={s.cert}>
              <img src={r(cert.logoUrl)} css={s.certIcon} alt={cert.certificationName} />
            </a>
          ) : (
            <span css={s.cert}>
              <img src={r(cert.logoUrl)} css={s.certIcon} alt={cert.certificationName} />
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

function Socials({ operator }) {
  const s = getSocialStyle();
  const socials = [];
  if (operator.facebookLink) socials.push({ title: "Facebook", icon: "/assets/social/facebook.png", link: operator.facebookLink });
  // if (operator.twitterLink) socials.push({ title: 'Twitter', icon: '/assets/social/twitter.png', link: operator.twitterLink })
  if (operator.instagramLink) socials.push({ title: "Instagram", icon: "/assets/social/instagram.png", link: operator.instagramLink });
  if (socials.length === 0) return null;
  return (
    <div css={s.socialIcons}>
      {socials.map((x) => (
        <a key={x.icon} href={x.link} target="_blank" rel="noopener noreferrer" css={s.socialIconLink}>
          <img src={r(x.icon)} css={s.socialIcon} alt={x.title} />
        </a>
      ))}
    </div>
  );
}

function Products({ operator }) {
  if (operator.products.length === 0) return null;
  const s = getProductStyle();
  return (
    <section css={s.products}>
      {operator.products.map((x) => {
        const heroImg = x.heroImageUrl ? r(set(x.heroImageUrl, "tiles_quare")) : null;
        return (
          <div key={x.productId} css={s.product}>
            {isPremium(operator) && heroImg && (
              <div css={s.imgCol}>
                <div css={s.img} style={{ backgroundImage: utils.css.bgUrlStyle(heroImg) }}></div>
              </div>
            )}
            <div css={s.content}>
              <h3 css={s.title}>{x.productName}</h3>
              <div css={s.desc}>{parse(x.description || "")}</div>
              {isPremium(operator) && x.productExtUrl && (
                <SiteLink to={x.productExtUrl} className="btn btn-sm btn-outline-primary">
                  Read More <GoLinkExternal />
                </SiteLink>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
}

function getMetaTag(operator, heroImage) {
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;
  const url = "/listing/" + operator.slug;

  return {
    title: operator.operatorName + " | " + titleSuffix,
    description: operator.metaDesc || operator.shortDesc,
    keywords: "",
    heroImageUrl: utils.site.fullUrl(heroImage.imageUrl),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(url),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
