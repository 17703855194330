/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useGoogleMaps } from "react-hook-google-maps";
import { useRouter } from 'components';
import { Popup } from './popup';
import utils from 'utils';
import { isInPWA } from '@site/_offline';

import './map.scss';

const apiKey = "AIzaSyDMvHsdjeYJsYCbXVAgHv4UVxfvTN06GLA"
//const apiKey = ""
const defaultCenter = {
  lat: -27.23032950276645,
  lng: 133.06420410319467
}

export function GoogleMap({ renderMarkersRef, initialMarkers = null, initialMapLocation = null, onUpdated = null, onLoaded = null, onBoundChanged = null }) {
  const { navigate } = useRouter();
  const loc = initialMapLocation ? {
    center: {
      lat: initialMapLocation.lat,
      lng: initialMapLocation.lng
    },
    zoom: initialMapLocation.zoom,
  } : {
      center: defaultCenter,
      zoom: 5,
    }

  const { ref, map, google } = useGoogleMaps(apiKey, loc);

  const currentMarkers = React.useRef([])

  const [popup, setPopup] = React.useState(null);


  function getUniqueMarkers(operators){
    const locs = []
    operators.forEach(op => {
      const found = utils.array.find(locs, x => x.lat === op.lat && x.lng === op.lng)
      if (found){
        found.operators.push(op)
      } else {
        locs.push({lat: op.lat, lng: op.lng, operators: [ op ]});
      }
    })
    return locs;
  }

  function renderMarkers(markers) {
    //console.log('renderMarkers', markers.length, currentMarkers.current.length)
    for (let i = 0; i < currentMarkers.current.length; i++) {
      currentMarkers.current[i].setMap(null);
    }

    // handle multiple pins for the exact location.
    const uniqueMarkers = getUniqueMarkers(markers)

    uniqueMarkers.forEach(marker => { 
      const m = addMarker(marker, map, google, navigate, setPopup);
      currentMarkers.current.push(m);
    })

    if (onUpdated)
      onUpdated(google, map, markers)
  }

  React.useEffect(() => {
    if (map) {
      //console.log('map loaded', initialMarkers)
      renderMarkersRef.current = renderMarkers
      if (initialMarkers)
        renderMarkers(initialMarkers)

      if (onLoaded)
        onLoaded(google, map)

      if (onBoundChanged)
        map.addListener('bounds_changed', () => onBoundChanged(map))
    }
    // eslint-disable-next-line
  }, [map, google, navigate]);

  return <div className="googleMapWrapper">
    <div ref={ref} className="googleMap" />
    {popup && <Popup popup={popup} setPopup={setPopup} />}
  </div>
}

export const GoogleMapMemo = React.memo(GoogleMap);

function addMarker(item, map, google, navigate, setPopup) {
  // var icon = {
  //   url: '/assets/icons/pin.png', // url
  //   size: new google.maps.Size(100, 132),
  //   scaledSize: new google.maps.Size(25, 33), // scaled size = 30 * 384/512
  //   origin: new google.maps.Point(0, 0), // origin
  //   anchor: new google.maps.Point(0, 16) // anchor
  // };

  const marker = new google.maps.Marker({
    position: { lat: item.lat, lng: item.lng },
    //icon: icon, 
    map
  })

  var infowindow = new window.google.maps.InfoWindow({
    content: getInfoWindowContent(item)
  });

  var isMobile = false;
  //var isMobile = true;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  }

  //window.matchMedia( "(hover: none)" ).matches

  marker.addListener('mouseover', function () {
    if (!isMobile) {
      infowindow.open(map, marker);
    }
  });
  marker.addListener('mouseout', function () {
    if (!isMobile) {
      infowindow.close();
    }
  });
  marker.addListener('click', function () {
    if (isMobile) {
      setPopup(item);
    }
    else {
      if (item.operators.length > 1){
        setPopup(item);
      } else {
        const slug = item.operators[0].slug
        if (isInPWA()) {
          navigate(`/listing/${slug}`)
        } else {
          window.open(`/listing/${slug}`)
        }
      }
      
    }
  });
  return marker;
}

function getInfoWindowContent(item) {
  const html = [];
  item.operators.forEach(operator => {
    const imageUrl = utils.site.resourcePath(operator.thumbnailImageUrl || '/assets/sample.jpg');
    html.push(`<div class="mapInfo_op"><a href="/listing/${operator.slug}" target="_blank">` +
    `<div class="mapInfo__photo" style='background-image:${utils.css.bgUrlStyle(imageUrl)}'></div>` +
    '<div class="mapInfo__title">' + operator.operatorName + '</div>' +
    '</a></div>')
  })
  return '<div class="mapInfo mapInfo--' + item.operators.length + '">' + html + '</div>'
}