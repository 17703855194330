/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useGoogleMaps } from "react-hook-google-maps";
import { useRouter } from 'components';
import { Popup } from './popup2';
import utils from 'utils';
//import { isInPWA } from '@site/_offline';

import './map.scss';

const apiKey = "AIzaSyDMvHsdjeYJsYCbXVAgHv4UVxfvTN06GLA"
//const apiKey = ""
const defaultCenter = {
  lat: -27.23032950276645,
  lng: 133.06420410319467
}

export function GoogleMap({ renderMarkersRef, initialMarkers = null, initialMapLocation = null, onUpdated = null, onLoaded = null, onBoundChanged = null }) {
  const { navigate } = useRouter();
  const loc = initialMapLocation ? {
    center: {
      lat: initialMapLocation.lat,
      lng: initialMapLocation.lng
    },
    zoom: initialMapLocation.zoom,
  } : {
      center: defaultCenter,
      zoom: 5,
    }

  const { ref, map, google } = useGoogleMaps(apiKey, loc);
  const currentMarkers = React.useRef([])
  const [popup, setPopup] = React.useState(null);

  function renderMarkers(markers) {
    //console.log('renderMarkers', markers.length, currentMarkers.current.length)
    for (let i = 0; i < currentMarkers.current.length; i++) {
      currentMarkers.current[i].setMap(null);
    }

    // handle multiple pins for the exact location.
    const uniqueMarkers = initialMarkers;

    uniqueMarkers.forEach(marker => {
      const m = addMarker(marker, map, google, navigate, setPopup);
      currentMarkers.current.push(m);
    })

    if (onUpdated)
      onUpdated(google, map, markers)
  }

  React.useEffect(() => {
    if (map) {
      //console.log('map loaded', initialMarkers)
      renderMarkersRef.current = renderMarkers
      if (initialMarkers)
        renderMarkers(initialMarkers)

      if (onLoaded)
        onLoaded(google, map)

      if (onBoundChanged)
        map.addListener('bounds_changed', () => onBoundChanged(map))
    }
    // eslint-disable-next-line
  }, [map, google, navigate]);

  return <div className="googleMapWrapper">
    <div ref={ref} className="googleMap" />
    {popup && <Popup popup={popup} setPopup={setPopup} />}
  </div>
}

export const GoogleMapMemo = React.memo(GoogleMap);

function addMarker(item, map, google, navigate, setPopup) {
  const marker = new google.maps.Marker({
    position: { lat: item.lat, lng: item.lng },
    //icon: icon, 
    map
  })

  var infowindow = new window.google.maps.InfoWindow({
    content: getInfoWindowContent(item)
  });

  var isMobile = false;
  //var isMobile = true;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  }

  //window.matchMedia( "(hover: none)" ).matches

  marker.addListener('mouseover', function () {
    if (!isMobile) {
      infowindow.open(map, marker);
    }
  });
  marker.addListener('mouseout', function () {
    if (!isMobile) {
      infowindow.close();
    }
  });
  marker.addListener('click', function () {
    if (isMobile) {
      setPopup(item);
    }
    else{
      // if (isInPWA()) {
      //   navigate(`/destination/${item.slug}`);
      // } else {
      //   window.open(`/destination/${item.slug}`)
      // }
      navigate(`/destination/${item.slug}`);
    }
  });
  return marker;
}

function getInfoWindowContent(item) {
  const imageUrl = utils.site.resourcePath((item.imageUrl && item.imageUrl !== '') ? item.imageUrl : '/assets/sample.jpg');
  const html = [];
  html.push(`<div class="mapInfo_op"><a href="/listing/${item.slug}" target="_blank">` +
    `<div class="mapInfo__photo" style='background-image:${utils.css.bgUrlStyle(imageUrl)}'></div>` +  
    '<div class="mapInfo__title">' + item.destinationName + '</div>' +
    '</a></div>')
  return '<div class="mapInfo mapInfo--">' + html + '</div>'
}