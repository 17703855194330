/** @jsx jsx */
import { jsx } from '@emotion/core'
import { getPanelStyle } from './panelStyle';

export function PanelCurved({ children }) {
  const s = getPanelStyle();
  return (
    <div className="container" css={s.pannelWrapper}>
      <div css={s.pannel}>
        {children}
      </div>
      <div css={s.pannelCurveBottomWrapper}><div css={s.pannelCurveBottom}></div></div>
    </div>
  );
}
