import { getDb } from './config';

export async function indexOfflineData(data, log) {
  const db = await getDb();
  log('Indexing pages - ' + data.pages.length)
  for (let i = 0; i < data.pages.length; i++) {
    const page = data.pages[i]
    await db.put('pages', page);
  }

  log('Indexing expTypes - ' + data.expTypes.length)
  for (let i = 0; i < data.expTypes.length; i++) {
    const expType = data.expTypes[i]
    await db.put('expTypes', expType);
  }
  
  log('Indexing destinations - ' + data.destinations.length)
  for (let i = 0; i < data.destinations.length; i++) {
    const dest = data.destinations[i]
    await db.put('destinations', dest);
  }

  log('Indexing operators - ' + data.operators.length)
  const featured = []
  for (let i = 0; i < data.operators.length; i++) {
    const op = data.operators[i]
    if (op.featured) featured.push(op)
    await db.put('operators', op);
  }

  log('Indexing featured - ' + featured.length)
  for (let i = 0; i < featured.length; i++) {
    await db.put('featured', featured[i]);
  }

  // for (let i = 0; i < data.imageUrls.length; i++) {
  //   await db.put('imageUrls', data.imageUrls[i]);
  // }

  return data;
}


// function loadFromIndexedDB(storeName, id){
//   return new Promise(
//     function(resolve, reject) {
//       var dbRequest = indexedDB.open(storeName);

//       dbRequest.onerror = function(event) {
//         reject(Error("Error text"));
//       };

//       dbRequest.onupgradeneeded = function(event) {
//         // Objectstore does not exist. Nothing to load
//         event.target.transaction.abort();
//         reject(Error('Not found'));
//       };

//       dbRequest.onsuccess = function(event) {
//         var database      = event.target.result;
//         var transaction   = database.transaction([storeName]);
//         var objectStore   = transaction.objectStore(storeName);
//         var objectRequest = objectStore.get(id);

//         objectRequest.onerror = function(event) {
//           reject(Error('Error text'));
//         };

//         objectRequest.onsuccess = function(event) {
//           if (objectRequest.result) resolve(objectRequest.result);
//           else reject(Error('object not found'));
//         };
//       };
//     }
//   );
// }