import { mq } from 'cssInJs'

export function getFilterStyle() {
  return {
    filters: mq({
     marginTop: [10],
     marginBottom: [10]
    }),
    searchKeyword: mq({
      height: [38]
    })
  }
}