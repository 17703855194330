import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: '#999',
    fontSize: ['12px', null, '15px'],
    'span': {
      display: ['none', null, 'inline']
    },
    'svg': {
      width: ['1.8em', null, '1.2em'],
      height: ['1.8em', null, '1.2em']
    }
  })),
}