/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { GoogleMapMemo } from './googleMap';
import env from 'env';
import { usePost } from 'components';
import { ErrorPopup } from 'components';

import { Filter } from './operatorFilter';
import { getFilterData, setFilterData } from './filterDataHelper';

// const GoogleMapMemo = React.memo(GoogleMap);

export function Map() {
  const [filters, setFilters] = React.useState(
    getFilterData('mapFilter', 1) || { typesOfBusiness: [], activities: [], features: [], keyword: '', state: null }
  );
  const [markers, setMarkers] = React.useState([]);
  const [markersLoaded, setMarkersLoaded] = React.useState(false);
  const [mapLoaded, setMapLoaded] = React.useState(false);

  const renderMarkersRef = React.useRef(null);

  const post = usePost();

  React.useEffect(() => {
    post.send(env.apiBase + `/api/operator/getMarkers`, {
      typesOfBusinessIds: (filters.typesOfBusiness || []).map((x) => x.value),
      activityIds: (filters.activities || []).map((x) => x.value),
      featureIds: (filters.features || []).map((x) => x.value),
      destinationIds: (filters.destinations || []).map((x) => x.value),
      keyword: filters.keyword,
      state: filters.state ? filters.state.value : '',
    });
    setMarkersLoaded(false);
    // eslint-disable-next-line
  }, [filters]);

  React.useEffect(() => {
    //console.log('markers changed', markers.length, renderMarkersRef.current)
    if (renderMarkersRef.current) renderMarkersRef.current(markers);
    // eslint-disable-next-line
  }, [markers, mapLoaded]);

  React.useEffect(() => {
    //console.log('filters changed', filters)
    setFilterData('mapFilter', 1, filters);
  }, [filters]);

  if (post.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={post.errors} />;
  }

  const data = post.response;
  if (post.done() && !markersLoaded) {
    setMarkersLoaded(true);
    setMarkers(data.operators);
  }

  function onMapLoaded() {
    setMapLoaded(true);
  }

  function onBoundChanged(map) {
    //console.log('onBoundChanged', map.getCenter(), map.getZoom())
    const center = map.getCenter();
    const zoom = map.getZoom();

    setFilterData('mapLocation', 1, { lng: center.lng(), lat: center.lat(), zoom: zoom });
  }

  const mapLocation = getFilterData('mapLocation', 1);

  return (
    <div>
      <Filter
        filters={filters}
        setFilters={setFilters}
        experienceTypes={(data && data.experienceTypes) || []}
        destinations={(data && data.destinations) || []}
      />
      <GoogleMapMemo renderMarkersRef={renderMarkersRef} initialMapLocation={mapLocation} onLoaded={onMapLoaded} onBoundChanged={onBoundChanged} />
    </div>
  );
}
