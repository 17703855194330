/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
// import { getDownloadAppStyle } from './downloadAppStyle';
import env from 'env';
import utils from 'utils';
import { fetchGet } from '@lib/net-api/fetch';
import { isInPWA, isOffline, isIOS, indexOfflineData, getStorageInfo, addCache, checkCache } from '@site/_offline';

/*
## Requirements
 - should be in PWA mode (which means PWA should be installed first)

## PROCESS
1. is in a PWA mode(standalone)?
  1.1 need to download? (PROCESS (DB Check))
    a. then display 'Download' button.


2. if not(in case of browser mode), display guide messages
  2.1 is the PWA app installed? (use api or prompt event, whatever it comes first. This will be an asynchrous checking.)
    a. then 'Open your PWA app'
    b. if not, 'Install PWA app'
    

* check by api - use navigator.getInstalledRelatedApps
* beforeinstallprompt event?
  - let it install but keep the event in window
  - display different message?
  - start install - status keep in window
  - appinstalled

## PROCESS (DB Check)
1. db version check
2. status
*/


const s = {
  screenshot: {
    width: 400,
    maxWidth: '100%'
  },
  screenshotSM: {
    width: 200,
    maxWidth: '50%'
  }
}

export function DownloadApp() {
  const [showLogCounter, setShowLogCounter] = React.useState(0)
  const [onlineStatus, setOnlineStatus] = React.useState(!isOffline())
  // '' => 'prompted', 'installed' (checked by api or install event)
  const [pwaInstallStatus, setPwaInstallStatus] = React.useState('')
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const debugLogsRef = React.useRef(window.debugLogs || [])
  const log = (...args) => { console.log(args); debugLogsRef.current = [...debugLogsRef.current, ...args] }

  React.useEffect(() => {
    const onlineSwitched = e => setOnlineStatus(navigator.onLine)
    const installPrompted = e => setPwaInstallStatus(pwaInstallStatus === 'installed' ? 'installed' : 'prompted')
    const appInstalled = e => setPwaInstallStatus('installed')

    window.addEventListener('online', onlineSwitched)
    window.addEventListener('offline', onlineSwitched)
    window.addEventListener('__pwaInstallPrompt', installPrompted)
    window.addEventListener('__pwaAppInstalled', appInstalled)
    return () => {
      window.removeEventListener('online', onlineSwitched)
      window.removeEventListener('offline', onlineSwitched)
      window.removeEventListener('__pwaInstallPrompt', installPrompted)
      window.removeEventListener('__pwaAppInstalled', appInstalled)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    log(env.ver + ': ' + env.updateDate)
    log('isOffline: ' + isOffline())
    log('pwaInstallStatus: ' + pwaInstallStatus)
    log('deferredPrompt: ' + window.deferredPrompt)
    log('deferredPromptResolved: ' + window.deferredPromptResolved)
    const fetchData = async () => {
      log('iOS: ' + isIOS())
      if (!navigator.getInstalledRelatedApps) {
        log('getInstalledRelatedApps: not supported')
        return
      }
      const relatedApps = await navigator.getInstalledRelatedApps();
      log('checking getInstalledRelatedApps:');
      relatedApps.forEach((app) => {
        log(app.id, app.platform, app.url);
        setPwaInstallStatus('installed')
        log('pwaInstallStatus: installed now')
      });
    }
    fetchData();
    // eslint-disable-next-line
  }, [])


  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getStorageInfo();
      log(...res);
    }
    fetchData();
  }, [])

  async function checkSize() {
    const result = await checkCache();
    const totalSize = utils.array.sum(result, x => x.size)
    log('Cache Size: ' + (totalSize / 1024 / 1024).toFixed(2) + 'MB')
    result.filter(x => x.size < 1000).map(x => log(x.size + ':' + x.url))
    forceUpdate()
  }

  return <div style={{ minHeight: 400 }}>
    {!onlineStatus ? <div>
      <h1>Download App</h1>
      <p>Currently, it seems offline.</p> <p>You can download the content only when you are online.</p>
    </div> : (!isInPWA() ? <GuideMessages pwaInstallStatus={pwaInstallStatus} /> : <CheckVersion log={log} />)}
    
    {showLogCounter < 2 && <div>
      <div style={{ marginTop: 50, height: 150 }} onClick={() => setShowLogCounter(showLogCounter + 1)}>&nbsp;</div>
    </div>}
    {showLogCounter >= 2 && <div>
      <h4 style={{ marginTop: 200 }}>Debug Message (will be removed when going live) <button onClick={forceUpdate}>update</button> <button onClick={checkSize}>check size</button></h4>
      <pre style={{ fontSize: 12 }}>{(debugLogsRef.current || []).join('\n')}</pre>
    </div>}
  </div>
}

//#region Guide messages and instructions
function GuideMessages({ pwaInstallStatus }) {
  return <div>
    {pwaInstallStatus === 'installed' ? <OpenPWAMessage /> : <InstallPWAMessage />}
  </div>
}

function OpenPWAMessage() {
  return <div>
    <h1>Download App</h1>
    <p>Offline Download is only available in an App mode. You seem to use a browser currently.</p>
    <p><b>Please open this page in your 'Green Travel Guide' App.</b></p>
    <p>Please find and open the App in your App list.</p>

    <p>To re-install 'Green Travel Guide' App, please read the following guide.</p>
    <InstallationInstruction />
  </div>
}

function InstallPWAMessage() {
  return <div>
    <h1>Download App</h1>
    <p>Offline Download is only available in an App mode. You seem to use a browser currently.</p>
    <p><b>Please install 'Green Travel Guide' App first.</b> If you believe you already have the App, open this page in your App</p>
    <p>To install 'Green Travel Guide' App, please read the following guide.</p>
    <InstallationInstruction />
  </div>
}

function InstallationInstruction() {
  return <div>
    {/* <p style={{color: 'chocolate'}}>Please note that the screen </p> */}
    {isIOS() ?
      <React.Fragment><InstallationInstructionIOS /><InstallationInstructionAndroid /></React.Fragment> :
      <React.Fragment><InstallationInstructionAndroid /><InstallationInstructionIOS /></React.Fragment>}
  </div>
}

function InstallationInstructionAndroid() {
  return <div>
    <hr/>
    <h3>Android</h3>

    <p>Once you have found the website a pop up will appear at the footer of the page. <b>Press “Add Green Travel to Home screen”</b></p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/android-add-to-home1.png" alt='Install PWA' /></div>

    <p>Please press 'Install' in the popup screen.</p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/android-add-to-home4b.png" alt='Install PWA' /></div>

    <p>The 'Green Travel Guide' App icon will instantly appear on your device home screen. When opened the app version of the Green Travel Guide website will launch.</p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/android-add-to-home6.png" alt='Install PWA' /></div>

    <p>Alternatively, you can install the App in the menu(top three dots menu). <b>Press 'Install app' menu.</b></p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshotSM} src="/assets/installation/android-add-to-home3.png" alt='Open menu' /></div>
    <p>If you already installed the App, you will see '' menu instead.</p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshotSM} src="/assets/installation/android-add-to-home5.png" alt='Open menu' /></div>

  </div>
}

function InstallationInstructionIOS() {
  return <div>
    <hr/>
    <h3>iOS (iPhone/iPad)</h3>

    <p><b>In the top of your browser, press the share icon. </b></p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/ios-add-to-home1.png" alt='Press share icon' /></div>
    <p>In the previous version of iOS, you can find the share icon in the bottom.</p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/ios-share-400.gif" alt='Press share icon - old version' /></div>

    <p>A series of options will appear. <b>Press "Add to Home Screen".</b></p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/ios-add-to-home2.png" alt='Install PWA' /></div>

    <p><b>Press the "Add" button.</b></p>
    <div style={{ marginBottom: '1rem' }}><img css={s.screenshot} src="/assets/installation/ios-add-to-home3.png" alt='Add PWA' /></div>

    <p>The 'Green Travel Guide' App icon will instantly appear on your device home screen. When opened the app version of the Green Travel Guide website will launch.</p>

  </div>
}
//#endregion

function CheckVersion({ log }) {
  const [versionChecked, setVersionChecked] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setVersionChecked(true)
    }
    fetchData();
  }, [])

  return <div>
  <h1>Download App</h1>
    {!versionChecked ? <p>Checking...</p> : <div>
      <p>Please press the 'Download' button to get the offline content.</p><DownloadContent log={log} />
    </div>}

  </div>
}

function DownloadContent({ log }) {
  const [data, setData] = React.useState({ offlineModel: {}, step: '', progress: 0, batchSize: 20, currentBatch: 0 });

  async function startDownload(e) {
    e.preventDefault()
    setData({ ...data, ...{ step: 'FETCH_DATA', progress: 5 } })
  }

  async function downloadImages() {
    //const images = await getAllImages()
    const progressPercentForImages = 65;
    const images = data.offlineModel.imageUrls;
    log('images: ' + images.length)
    const batchSize = data.batchSize, currentBatch = data.currentBatch;
    const maxBatch = Math.ceil(images.length / batchSize);
    //console.log(images.length, images[0], maxBatch)

    if (currentBatch < maxBatch) {
      const from = currentBatch * batchSize, to = from + batchSize > images.length ? images.length : from + batchSize;
      //console.log(from, to, Math.floor((currentBatch + 1) / maxBatch * progressPercentForImages))
      const batchImages = images.slice(from, to);
      await addCache(batchImages.map(x => x.imageUrl), log)
      setData({
        ...data, ...{
          step: currentBatch === maxBatch - 1 ? 'FINALISING' : 'GET_IMAGES',
          currentBatch: currentBatch + 1,
          progress: 20 + Math.floor((currentBatch + 1) / maxBatch * progressPercentForImages)
        }
      })
    } else {
      // completed - should not reach here. just in case.
      setData({ ...data, ...{ step: 'FINALISING', progress: 30 + progressPercentForImages } })
    }
  }

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const step = data.step;
    log('Step :' + data.step)
    const asyncProcess = async () => {
      try {
        if (step === 'FETCH_DATA') {
          const offlineModel = await fetchGet(env.apiBase + '/api/offline/GetOfflineModel', signal);
          console.log(offlineModel)
          setData({ ...data, ...{ step: 'STORE_DATA', progress: 15, offlineModel: offlineModel } })
        } else if (step === 'STORE_DATA') {
          await indexOfflineData(data.offlineModel, log)
          setData({ ...data, ...{ step: 'GET_IMAGES', progress: 30 } })
        } else if (step === 'GET_IMAGES') {
          await downloadImages()
        } else if (step === 'FINALISING') {
          setData({ ...data, ...{ step: 'DONE', progress: 100 } })
        }
      } catch (ex) {
        console.log(ex)
      }

    }
    asyncProcess();
    return () => {
      //console.log("cleaning up");
      controller.abort();
    };
    // eslint-disable-next-line 
  }, [data])

  let statusMessage = '';
  if (data.step === 'FETCH_DATA') statusMessage = 'Fetching data...'
  else if (data.step === 'STORE_DATA') statusMessage = 'Storing data...'
  else if (data.step === 'GET_IMAGES') statusMessage = 'Downloading images...'
  else if (data.step === 'FINALISING') statusMessage = 'Finalising...'
  else if (data.step === 'DONE') statusMessage = 'Downloaded.'

  return <React.Fragment>
    <button className="btn btn-primary" disabled={data.step !== ''} onClick={startDownload}>Download</button>
    {data.progress > 0 && <div style={{ marginTop: 20 }}>
      <div className="progress">
        <div className="progress-bar bg-warning" role="progressbar" style={{ width: data.progress + '%' }} aria-valuenow={data.progress} aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div style={{ color: 'chocolate' }}>{statusMessage} {data.progress}%</div>
    </div>}
    {data.step === 'DONE' && <div>
      <p style={{margin: '20px 0'}} className="alert alert-success" role="alert">Successfully installed. Now you can enjoy the offline version when you don't have any Internet connection.</p>
      </div>}
  </React.Fragment>
}


// export function DownloadApp2() {
//   const get = useGet();
//   const [data, setData] = React.useState();
//   const [step, setStep] = React.useState('');
//   const [statusMessage, setStatusMessage] = React.useState(null);
//   const [response, setResponse] = React.useState(null);

//   const s = getDownloadAppStyle();

//   console.log('DownloadApp', statusMessage)
//   async function download(e) {
//     e.preventDefault()
//     console.log('download')
//     // setStep('FETCH_DATA')

//     setStatusMessage('Fetching data...');
//     const res = await fetchGet(env.apiBase + '/api/offline/GetOfflineModel');
//     setStatusMessage('Storing data...');
//     console.log(res)
//     await indexOfflineData(res)
//     setStatusMessage('Downloaded');
//     const images = await getAllImages()
//     const batchSize = 10;
//     const batchIndex = Math.ceil(images.length / batchSize);

//     console.log(images.length, images[0], batchIndex)

//     for (let i = 0; i < batchIndex; i++) {
//       const from = i * batchSize, to = from + batchSize > images.length ? images.length : from + batchSize;
//       console.log(from, to)
//       const batchImages = images.slice(from, to);
//       //console.log(batchImages.map(x => x.imageUrl))

//       await addCache(batchImages.map(x => x.imageUrl))
//     }

//     setStatusMessage('Downloading images');
//     // await checkCache();
//     // await addCache();
//   }

//   // React.useEffect(() => {
//   //   const controller = new AbortController();
//   //   const signal = controller.signal;
//   //   console.log('step' , step)
//   //   const asyncProcess = async () => {
//   //     try {
//   //       if (step === 'FETCH_DATA') {
//   //         const res = await fetchGet(env.apiBase + '/api/offline/GetOfflineModel', signal);
//   //         console.log(res)
//   //         console.log('1')
//   //         setStatusMessage('Storing data...');
//   //         console.log('2')
//   //         setData(res)
//   //         console.log('3')
//   //         setStep('STORE_DATA')
//   //       } else if (step === 'STORE_DATA') {
//   //         await indexOfflineData(data)
//   //         setStatusMessage('Downloaded');
//   //         setStep('GET_IMAGES')
//   //       }
//   //     }catch(ex){
//   //       console.log(ex)
//   //     }

//   //   }
//   //   asyncProcess();
//   //   return () => {
//   //     console.log("cleaning up");
//   //     controller.abort();
//   //   };
//   // }, [step])

//   React.useEffect(() => {
//     const fetchData = async () => {
//       const res = await getStorageInfo();
//       setResponse(res);
//     }
//     fetchData();
//   }, [])


//   return <React.Fragment>
//     <h1>Download App</h1>
//     <div onClick={download}>Download</div>
//     <div style={{ color: 'red' }}>{statusMessage}</div>
//     <pre>{(response || []).join('\n')}</pre>
//   </React.Fragment>
// }
