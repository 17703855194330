/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { GoogleMapMemo } from "./googleMap";
// import { getFilterData, setFilterData } from './filterDataHelper';

// const GoogleMapMemo = React.memo(GoogleMap);

export function DestinationMap({ destinationId, operators }) {
  const [markers, setMarkers] = React.useState(operators || []);

  const renderMarkersRef = React.useRef(null);
  const renderedRef = React.useRef(false);

  React.useEffect(() => {
    setMarkers(operators)
  }, [operators]);

  React.useEffect(() => {
    //console.log('markers changed', markers.length, renderMarkersRef.current)
    if (renderMarkersRef.current) renderMarkersRef.current(markers)
    // eslint-disable-next-line
  }, [markers, renderMarkersRef.current]);

  function onUpdated(google, map, markers) {
    //console.log(map, markers)
    if (markers.length === 0) return;
    if (renderedRef.current) return;

    //  Create a new viewpoint bound
    var bounds = new google.maps.LatLngBounds();
    //  Go through each...
    for (var i = 0; i < markers.length; i++) {
      //console.log(markers[i])
      bounds.extend(new google.maps.LatLng(markers[i].lat, markers[i].lng));
    }
    //  Fit these bounds to the map
    map.fitBounds(bounds);
    renderedRef.current = true
  }

  // function onBoundChanged(map) {
  //   //console.log('onBoundChanged', map.getCenter(), map.getZoom())
  //   const center = map.getCenter()
  //   const zoom = map.getZoom()
    
  //   setFilterData('mapLocationDest' + destinationId, 1, { lng: center.lng(), lat: center.lat(), zoom: zoom })
  // }

  // const mapLocation = getFilterData('mapLocationDest' + destinationId, 1)
  // console.log(mapLocation)

  return <div>
    <GoogleMapMemo
      renderMarkersRef={renderMarkersRef}
      // initialMapLocation={mapLocation}
      initialMarkers={markers} 
      onUpdated={onUpdated}
      // onBoundChanged={onBoundChanged}
      />
  </div>
}
