/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from '../../utils';
import { mq } from '../../cssInJs';

export function ExperienceIcon({ expTypes }) {
  if (!expTypes) return null;
  const s = getIconStyle();
  return (
    <div css={s.expIcons}>{
      expTypes.map(expType => <img css={s.expIcon} key={expType.experienceTypeId}
        src={utils.site.resourcePath(expType.iconUrl, 'default')}
        alt={expType.experienceTypeName} title={expType.experienceTypeName} />)
    }</div>
  )
}

function getIconStyle() {
  return {
    expIcons: mq({
      margin: '0.3rem 0'
    }),
    expIcon: mq({
      width: [30, null, 40],
      marginRight: [7],
      display: 'inline-block !important',
    }),
  }
}