import { mq } from '../../cssInJs';

export function getExpStyle() {
  return {
    container: {
      maxWidth: 1200
    },
    title: mq({
      fontSize: [28, 36, 48, 62],
      marginTop: 0
    }),
    desc: {
      margin: '1rem 0',
      'p:first-of-type': {
        fontWeight: 'bold',
        fontSize: '1.1rem'
      }
    }
  }
}


export function getProductStyle() {
  return {
    products: {
      marginTop: '1.5rem',
    },
    product: mq({
      padding: '0.8rem 0',
      margin: '0 0 0.8rem 0',
      borderBottom: 'dashed 1px #ccc',
      display: ['block', 'flex'],
    }),
    imgCol: mq({
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '20%',
      maxWidth: ['100%', 200],
      marginRight: [0, 20],
      marginBottom: [15, 0]
    }),
    img: mq({
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '0',
      paddingBottom: ['56.25%', '100%'],
    }),
    content: {
      
    },
    title: mq({
      marginTop: 0,
      //fontSize: [18, 22, 30, 36],
    }),
    desc: {
    },
  }
} 
