
import { renderRule as allDestinationMap } from './allDestinationMap/render';
import { renderRule as code_experienceList } from './code-experienceList/render';
import { renderRule as code_featuredList } from './code-featuredList/render';
import { renderRule as code_instagram } from './code-instagram/render';
import { renderRule as code_map } from './code-map/render';
import { renderRule as code_subscribe } from './code-subscribe/render';
import { renderRule as destinationMap } from './destinationMap/render';
import { renderRule as destinationOperators } from './destinationOperators/render';
import { renderRule as html } from './html/render';
import { renderRule as imagePanel } from './imagePanel/render';
import { renderRule as pageTiles } from './pageTiles/render';
import { renderRule as panel } from './panel/render';
import { renderRule as photoGallery } from './photoGallery/render';
import { renderRule as sharingIcons } from './sharingIcons/render';
import { renderRule as singleBanner } from './singleBanner/render';
import { renderRule as videoPanel } from './videoPanel/render';
export const items = [
  allDestinationMap,
  code_experienceList,
  code_featuredList,
  code_instagram,
  code_map,
  code_subscribe,
  destinationMap,
  destinationOperators,
  html,
  imagePanel,
  pageTiles,
  panel,
  photoGallery,
  sharingIcons,
  singleBanner,
  videoPanel,
]