import { css } from '@emotion/core'
import { mq } from 'cssInJs';

export function getPopupStyle() {
  return {
    popupBg: css({
      backgroundColor: 'rgba(0,0,0,0.3)',
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      zIndex: '9999'
    }),
    container: css({
      position: 'fixed',
      width: '95%',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: '-1rem',
      zIndex: '9999',
      backgroundColor: 'white',
      padding: '1rem',
      maxWidth: '450px',
      maxHeight: '90vh',
      overflow: 'auto',
    }),
    multiple: {
      // maxWidth: 900,
      // display: 'flex',
      // flexWrap: 'wrap',

    },
    tileBg: mq({
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '56.25%'
    }),
    popupTitle: css({
      fontWeight: '700',
      fontSize: '1rem',
      textAlign: 'center',
    }),
    line: css({
      fontWeight: '300',
      fontSize: '0.9rem',
      margin: '0.2rem 0px'
    }),
    popupDesc: css({
      fontSize: '0.8rem'
    }),
    button: css({
      fontWeight: '700'
    }),
    operator: {
      marginBottom: '1em'
    }
  }
}