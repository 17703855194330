import utils from "../utils";
import env from "../env";
import { isMin } from "../lib/css-emotion";
import { isOffline, getOfflineMedia } from "@site/_offline";

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md();
};

export function resourcePath(url, setId) {
  if (!url) return null;
  if (url && url.indexOf("/assets/") === 0) return url;
  if (utils.url.isExternalLink(url)) return url;
  return isOffline()
    ? env.resourceBase + getOfflineMedia(url, setId)
    : env.resourceBase + url;
}

export function fullUrl(url) {
  return utils.url.isExternalLink(url) ? url : env.baseOrigin + url;
}

export const isNZ = env.locale === "nz";

export const cursor = "url('/assets/icons/gekko-icon.png') 16 16, pointer";
