/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useRouter } from 'components';
import { getPopupStyle } from './popupStyle';
import { Portal } from "site/portal/portal";
import utils from 'utils';

import { isInPWA } from '@site/_offline';

export function Popup({ popup, setPopup }) {
  // console.log(popup)
  const { navigate } = useRouter();
  const s = getPopupStyle();
  console.log(popup)

  return <Portal>
    {
      popup &&
      <React.Fragment>
        <div css={s.popupBg}></div>
        <div css={[s.container, popup.operators.length > 1? s.multiple : null]}>
          {popup.operators.map(x => {
            const imageUrl = utils.site.resourcePath(x.thumbnailImageUrl || '/assets/sample.jpg');
            return <div key={x.slug} css={s.operator}>
              <div css={s.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
              <div css={s.popupTitle}> {x.operatorName} </div>
              {/* <div css={s.line}> {popup.address} </div>
        <div css={s.line}> {popup.suburb} </div>
        <div css={s.line}> {popup.state} </div> */}
              <div style={{ textAlign: 'center' }}>
                <button className="btn btn-primary" onClick={() => {
                  if (isInPWA()) {
                    navigate(`/listing/${x.slug}`)
                  } else {
                    window.open(`/listing/${x.slug}`)
                  }
                }}>See More</button>
                <button className="btn btn-primary" style={{ marginLeft: '1rem' }} onClick={() => {
                  setPopup(null);
                }} >Close</button>
              </div>

            </div>
          })}
        </div>
      </React.Fragment>
    }
  </Portal>
}