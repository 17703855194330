import { variables, mq } from '../../cssInJs';

const buttonColor = variables.colorBlue;
export function getDownloadAppRibbonStyle(heightSet) {

  return {
    ribbon: mq({
      position: 'absolute',
      //           sm      md      lg      xl      xxl
      //    <576   >=576   >=768   >=992   >=1200  >=1600
      top: ['40%', '45%',  '45%',  '45%',  '45%',  '45%'],
      right: '0',
      //transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      zIndex: 11,
      //      <576   >=576   >=768   >=992   >=1200  >=1600
      width: [250,   250,    250,    300,    300,    300],
      //       <576   >=576   >=768   >=992   >=1200  >=1600
      height: [200,   250,    250,    300,    300,    300 ],
      background: '#f5f5f5'
    }),
    handle: mq({
      cursor: 'ew-resize',
      color: 'white',
      fontSize: ['1rem', null, '1.2rem',],
      fontWeight: 'bold',
      transform: 'rotate(90deg) translate(-50%, 0px)',
      position: 'absolute',
      top: '50%',
      left: '0',
      textAlign: 'center',
      transformOrigin: 'left top',
      borderRadius: '200%',
      paddingTop: [5, 10],
      paddingBottom: [12, 15],
      //      <576   >=576   >=768   >=992   >=1200  >=1600
      width: [200,   250,    250,    300,    300,    300 ],
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      letterSpacing: '1px',
      background: buttonColor
    }),
    content: mq({
      padding: '10px 15px',
      fontSize: ['0.9rem', null, '1rem'],
      'h4': {
        fontSize: ['1rem', null, '1.25rem'],
      },
      'p': {
        lineHeight: 1.3
      }
    })
  }
}