/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useRouter } from 'components';
import { getPopupStyle } from './popupStyle';
import { Portal } from "site/portal/portal";
//import { isInPWA } from '@site/_offline';
import utils from 'utils';

export function Popup({ popup, setPopup }) {
  console.log('dsfsdfsfsfs', popup)
  const { navigate } = useRouter();
  const s = getPopupStyle();
  const imageUrl = utils.site.resourcePath((popup.imageUrl && popup.imageUrl !== '') ? popup.imageUrl : '/assets/sample.jpg');

  return <Portal>
    {
      popup &&
      <React.Fragment>
        <div css={s.popupBg}></div>
        <div css={s.container}>
          <div css={s.operator}>
            <div css={s.tileBg} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
            <div css={s.popupTitle} style = {{marginBottom:'0.2rem'}}> {popup.destinationName} </div>
            <div style={{ textAlign: 'center' }}>
              <button className="btn btn-primary" onClick={() => {
                  // if (isInPWA()) {
                  //   navigate(`/destination/${popup.slug}`)
                  // } else {
                  //   window.open(`/destination/${popup.slug}`)
                  // }
                  navigate(`/destination/${popup.slug}`);
                }}>See More</button>
                <button className="btn btn-primary" style={{ marginLeft: '1rem' }} onClick={() => {
                  setPopup(null);
                }} >Close</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    }
  </Portal>
}